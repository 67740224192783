const styles = {
  error: {
    color: '#f44336',
  },
  info: {
    color: '#2196f3',
  },
  success: {
    color: '#4caf50',
  },
};

export default styles;
