import { api } from './';

import { ILoginInput } from 'src/interfaces/user';

const AuthService = {
  signIn: async (input: ILoginInput) => {
    try {
      const res = await api.post('/auth/signin', input);
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  verifyIdentity: async () => {
    try {
      const res = await api.get('/users/me');
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  me: async () => {
    try {
      const res = await api.get('/users/me');
      return res.data;
    } catch (e) {
      throw e;
    }
  },
};

export default AuthService;
