import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';

import { Container, Title, GoBack } from 'src/components';
import { IReduxState } from 'src/store';
import { fetchErasedPackageItems } from 'src/store/packages/actions';
import { Notification } from 'src/utils';

import { IProps } from './erased.type';
import FilterComponent from './components/filter/filter.component';
import TableComponent from './components/table/table.component';
import PaginationComponent from './components/pagination/pagination.component';
import { IFilterPackageInput } from 'src/interfaces/package';

const PackageScreen: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const user = useSelector((state: IReduxState) => state.auth?.user);

  const [loading, setLoading] = useState(false);

  const { packages, filter } = props;

  const handleSearch = async (
    filter: IFilterPackageInput,
    pageNumber: number
  ) => {
    setLoading(true);

    try {
      await props.fetchErasedPackageItems(filter, pageNumber);
    } catch (e) {
      Notification.error({ message: 'Ocorreu um erro ao buscar os cortes' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user?.canDelete) {
      navigate('/painel/pacotes/');
      return Notification.info({
        title: 'Desculpe!',
        message: 'Você não possui permissão para acessar essa página',
      });
    }

    handleSearch(filter, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container type="fluid">
      <Row gutter={16} className="mt-4" align="middle" justify="space-between">
        <Col>
          <GoBack color="#444" />
          <Title
            foreword="Pacotes"
            title="Lixeira"
            subtitle="Lista de pacotes excluidos, clique no ícone para restaurar o pacote."
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <FilterComponent
            loading={loading}
            handleSearch={handleSearch}
            filter={filter}
            currentPage={Number(packages?.meta.currentPage) || 1}
          />
        </Col>

        <Col span={24}>
          <TableComponent
            loading={loading}
            dataSource={packages?.data}
            handleSearch={handleSearch}
            currentPage={Number(packages?.meta.currentPage) || 1}
            filter={filter}
          />
        </Col>
        <Col span={24}>
          <PaginationComponent
            pagesMeta={packages?.meta}
            handleSearch={handleSearch}
            filter={filter}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  packages: state.packages.erased,
  filter: state.packages.erasedFilter,
});

export default connect(mapStateToProps, { fetchErasedPackageItems })(
  PackageScreen
);
