import React from 'react';
import moment from 'moment';

import { Wrapper } from './footer.style';

const FooterComponent: React.FC = () => {
  return (
    <Wrapper>© {moment().format('Y')} - Todos os direitos reservados.</Wrapper>
  );
};

export default FooterComponent;
