import React, { useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { Card } from 'src/components';
import RedirectIcon from 'src/assets/icons/key.gif';
import { Notification } from 'src/utils';
import { verifyIdentity, logout } from 'src/store/auth/actions';

import { IProps } from './redirect.type';
import { Wrapper, Title, Image, Subtitle } from './redirect.style';

const RedirectScreen: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const [cookies] = useCookies();

  const handleSignIn = async () => {
    const authentication_token = cookies._jwtToken;

    if (!!authentication_token) {
      try {
        const response = await props.verifyIdentity();

        if (!!response) {
          navigate('/painel/pacotes');
        }
      } finally {
        return;
      }
    } else {
      Notification.warning({
        message: 'Você precisa estar logado para acessar essa página.',
      });

      navigate('/');
    }
  };

  const handleLogout = () => {
    props.logout();
    window.location.reload();
  };

  useEffect(() => {
    setTimeout(() => handleSignIn(), 3000);
  });

  return (
    <>
      <Wrapper>
        <div className="py-5" style={{ width: '100%' }}>
          <Row justify="center" align="middle">
            <Col xs={20} sm={20} md={14} lg={8} xxl={6}>
              <Card className="text-center">
                <Image src={RedirectIcon} alt="login" />
                <Title>Confirmando Identidade</Title>
                <Subtitle>
                  Você será redirecionado para o módulo de pacotes em breve
                </Subtitle>

                <Button type="dashed" onClick={() => handleLogout()}>
                  <b> Fazer Login </b>
                </Button>
              </Card>
            </Col>
          </Row>
        </div>
      </Wrapper>
    </>
  );
};

export default connect(undefined, { verifyIdentity, logout })(RedirectScreen);
