import { isMobile } from 'react-device-detect';

interface ITable {
  bordered?: boolean;
  locale: any;
  size?: 'small' | 'middle';
  scroll?: {
    x: number | 'true' | 'false' | 'max-content';
  };
}

const table: ITable = {
  locale: {
    emptyText: 'Nenhum dado a ser exibido.',
  },
  size: 'small',
  bordered: false,
  scroll: isMobile ? { x: 'max-content' } : { x: 'true' },
};

export default table;
