import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form } from 'antd';

import { Container, GoBack, Title, Card } from 'src/components';
import { IReduxState } from 'src/store';

import InformationComponent from './components/information/information.component';

import { IProps } from './audit.type';

import StepComponent from './components/step/step.component';
import PacksComponent from './components/packs/packs.component';

const AuditScreen: React.FC<IProps> = (props) => {
  const { currentOrder, measurements, stepReport, packagesReport, steps } =
    props;

  const [form] = Form.useForm();

  return (
    <Container type="fluid">
      <Row gutter={16} className="mt-4" align="middle" justify="space-between">
        <Col>
          <GoBack color="#444" />
          <Title
            foreword="Pacotes"
            title="Auditoria"
            subtitle="Faça a auditoria de pacotes buscando pela OC, pedido e estágio."
          />
        </Col>
      </Row>
      <Row gutter={16} className="mt-0">
        <Col span={24}>
          <InformationComponent form={form} />
        </Col>

        {!!currentOrder && !!measurements?.length && !!stepReport?.length ? (
          <Col span={24}>
            <Card>
              <StepComponent
                measurements={measurements}
                currentOrder={currentOrder}
                stepReport={stepReport}
                form={form}
                steps={steps}
              />
              <PacksComponent
                measurements={measurements}
                currentOrder={currentOrder}
                stepReport={stepReport}
                packagesReport={packagesReport}
              />
            </Card>
          </Col>
        ) : null}
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  currentOrder: state.audit.currentOrder,
  measurements: state.audit.measurements,
  stepReport: state.audit.stepReport,
  packagesReport: state.audit.packagesReport,
  steps: state.steps.all,
});

export default connect(mapStateToProps, {})(AuditScreen);
