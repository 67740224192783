import React from 'react';
import { Row, Tooltip } from 'antd';
import { AiOutlinePlus } from 'react-icons/ai';

import { IProps } from './header.type';
import styles, { CardHeader, Title, Subtitle, Button } from './header.style';

const HeaderComponent: React.FC<IProps> = (props) => {
  const { title, required, subtitle, button } = props;

  return (
    <CardHeader>
      <Row align="middle" justify="space-between">
        <Title>
          {title}
          {required && <span style={styles.required}>*</span>}
        </Title>
        <div>
          {!!button?.length &&
            button.map((item: any, key: any) => (
              <Tooltip title={item.title}>
                <Button
                  key={key}
                  href={item?.href}
                  target={item?.target}
                  type={item?.type || 'primary'}
                  size="small"
                  onClick={() => !!item?.action && item.action()}
                  disabled={!!item.disabled}
                  className="mx-1"
                  color={item.color}
                >
                  {item.icon || (
                    <AiOutlinePlus size="17" className="v-align-bottom" />
                  )}
                </Button>
              </Tooltip>
            ))}
        </div>
      </Row>
      {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
    </CardHeader>
  );
};

export default HeaderComponent;
