import { api } from './';

import {
  ICreatePackageInput,
  IUpdateQuantityInput,
  IFilterPackageInput,
  ICreatePackageItemChildren,
} from 'src/interfaces/package';

const PackagesService = {
  all: async (filter?: IFilterPackageInput, pageNumber?: number) => {
    try {
      const res = await api.get('packages/list', {
        params: {
          cutOrderName: filter?.cutOrderName || '',
          orderNumber: filter?.orderNumber || '',
          packageItemId: filter?.packageItemId || '',
          page: pageNumber || 1,
        },
      });

      return res.data;
    } catch (e) {
      throw e;
    }
  },
  fetchPackagesFromOrderByCutOrder: async (
    orderId: string,
    cutOrderName: string
  ) => {
    try {
      const res = await api.get(
        `/packages/filter?orderId=${orderId}&cutOrderName=${cutOrderName}`
      );
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  current: async (id: string) => {
    try {
      const res = await api.get(`/packages/${id}`);
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  currentPackageItem: async (id: string) => {
    try {
      const res = await api.get(`/package_items/${id}`);
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  createPackageItemChildren: async (
    id: number,
    input: ICreatePackageItemChildren
  ) => {
    try {
      const res = await api.put(`/package_items/${id}/create_children`, input);
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  create: async (input: ICreatePackageInput) => {
    try {
      const res = await api.post('/packages', input);
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  updateQuantity: async (id: string, input: IUpdateQuantityInput) => {
    try {
      const res = await api.patch(`/package_items/${id}`, input);
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  fetchErasedPackageItems: async (
    filter?: IFilterPackageInput,
    pageNumber?: number
  ) => {
    try {
      const res = await api.get('/package_items/list_deleteds', {
        params: {
          cutOrderName: filter?.cutOrderName || '',
          packageItemId: filter?.packageItemId || '',
          page: pageNumber || 1,
        },
      });

      return res.data;
    } catch (e) {
      throw e;
    }
  },
  restore: async (id: string) => {
    try {
      const res = await api.put(`/package_items/${id}/restore`);
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  remove: async (id: string) => {
    try {
      const res = await api.delete(`/package_items/${id}`);
      return res.data;
    } catch (e) {
      throw e;
    }
  },
};

export default PackagesService;
