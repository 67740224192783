import React, { useState, useEffect, useContext, useRef } from 'react';
import { Form, Input } from 'antd';

import type { InputRef } from 'antd';

import { EditableContext } from '../../table.component';
import { IProps } from './cell.type';

const EditableCellComponent: React.FC<IProps> = (props) => {
  const {
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  } = props;

  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);

    form.setFieldsValue({
      [dataIndex]: record[dataIndex] || 0,
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();

      handleSave({ ...record }, values);
    } finally {
      return;
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        <Input
          ref={inputRef}
          onPressEnter={save}
          onBlur={save}
          style={{ maxWidth: '100px' }}
        />
      </Form.Item>
    ) : (
      <div
        tabIndex={0}
        onFocus={() => toggleEdit()}
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
      >
        {children}
      </div>
    );
  }

  return (
    <td tabIndex={-1} {...restProps}>
      {childNode}
    </td>
  );
};

export default EditableCellComponent;
