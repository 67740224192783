import {
  IPackage,
  IItem,
  IPaginatePackages,
  IFilterPackageInput,
  IPackageItem,
} from 'src/interfaces/package';

export const FETCH_PACKAGES = 'FETCH_PACKAGES';
export const FETCH_PACKAGE = 'FETCH_PACKAGE';
export const FETCH_PACKAGE_ITEM = 'FETCH_PACKAGE_ITEM';
export const FETCH_PACKAGES_FROM_ORDER_BY_CUT_ORDER =
  'FETCH_PACKAGES_FROM_ORDER_BY_CUT_ORDER';
export const FETCH_ERASED_PACKAGE_ITEMS = 'FETCH_ERASED_PACKAGE_ITEMS';
export const UPDATE_PACKAGES = 'UPDATE_PACKAGES';
export const UPDATE_FILTER_PACKAGES = 'UPDATE_FILTER_PACKAGES';
export const UPDATE_FILTER_ERASED_PACKAGES = 'UPDATE_FILTER_ERASED_PACKAGES';
export const UPDATE_TABLE_ITEM = 'UPDATE_TABLE_ITEM';

export interface IReducerPackagesState {
  all?: IPaginatePackages;
  filter: IFilterPackageInput;
  new?: {
    items: IItem[] | any;
    packages: IPackage[] | any;
  };
  current?: IPackage;
  currentItem?: IPackageItem;
  erased?: IPaginatePackages;
  erasedFilter: IFilterPackageInput;
}

export interface IFetchPackages {
  type: typeof FETCH_PACKAGES;
  payload: IPaginatePackages;
}

export interface IFetchPackage {
  type: typeof FETCH_PACKAGE;
  payload: IPackage;
}

export interface IFetchPackagesFromOrderByCutOrder {
  type: typeof FETCH_PACKAGES_FROM_ORDER_BY_CUT_ORDER;
  payload: any;
}

export interface IFetchPackageItem {
  type: typeof FETCH_PACKAGE_ITEM;
  payload: IPackageItem;
}

export interface IUpdateTableItem {
  type: typeof UPDATE_TABLE_ITEM;
  payload: any;
}

export interface IUpdateFilterPackages {
  type: typeof UPDATE_FILTER_PACKAGES;
  payload: IFilterPackageInput;
}

export interface IUpdatePackages {
  type: typeof UPDATE_PACKAGES;
  payload: IPackage[];
}

export interface IFetchErasedPackageItems {
  type: typeof FETCH_ERASED_PACKAGE_ITEMS;
  payload: IPaginatePackages;
}

export interface IUpdateFilterErasedPackages {
  type: typeof UPDATE_FILTER_ERASED_PACKAGES;
  payload: IFilterPackageInput;
}

export type PackagesActionTypes =
  | IFetchPackages
  | IFetchPackage
  | IFetchPackagesFromOrderByCutOrder
  | IFetchPackageItem
  | IFetchErasedPackageItems
  | IUpdateFilterPackages
  | IUpdateFilterErasedPackages
  | IUpdateTableItem
  | IUpdatePackages;
