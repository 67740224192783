import styled from 'styled-components';

export const Wrapper = styled.div`
  background: white;
  min-height: 100%;
  min-width: 100%;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden !important;
  position: relative;

  .ant-progress-bg {
    height: 2px !important;
  }
`;

export const Title = styled.h1`
  color: #111;
  letter-spacing: 3px;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
`;

const Default = {
  bar: {
    height: 2,
    border: 'none',
    maxWidth: 400,
    margin: 'auto',
  },
};

export default Default;
