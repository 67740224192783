import React, { useEffect, useState } from 'react';
import { Table, Skeleton } from 'antd';

import { connect } from 'react-redux';

import { Card } from 'src/components';
import { table } from 'src/utils';
import { restorePackageItem } from 'src/store/packages/actions';

import { IProps } from './table.type';
import { columns } from './table.utils';

const TableComponent: React.FC<IProps> = (props) => {
  const { dataSource, loading, filter } = props;

  const [data, setData] = useState(dataSource);

  const handleRestore = async (id: string) => {
    try {
      const response = await props.restorePackageItem(id);
      if (response) {
        props.handleSearch(filter, props.currentPage);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);

  return (
    <Card>
      {loading ? (
        <Skeleton active />
      ) : (
        <Table
          {...table}
          rowKey={(record: any) => 'r' + record.id}
          columns={columns(handleRestore)}
          dataSource={data}
          bordered
          pagination={false}
        />
      )}
    </Card>
  );
};

export default connect(undefined, { restorePackageItem })(TableComponent);
