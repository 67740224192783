import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

import { IReducerConfigState } from './config/types';
import { IReducerAuthState } from './auth/types';
import { IReducerPackagesState } from './packages/types';
import { IReducerOrderState } from './orders/types';
import { IReducerStepsState } from './steps/types';
import { IReducerAuditState } from './audit/types';

const middleware = [thunk];

export const store = createStore(
  rootReducer,
  undefined,
  process.env.REACT_APP_ENV_API_KEY === 'production'
    ? applyMiddleware(...middleware)
    : require('redux-devtools-extension').composeWithDevTools(
        applyMiddleware(...middleware)
      )
);

export const persistor = persistStore(store);

export interface IReduxState {
  config: IReducerConfigState;
  auth: IReducerAuthState;
  packages: IReducerPackagesState;
  orders: IReducerOrderState;
  steps: IReducerStepsState;
  audit: IReducerAuditState;
}
