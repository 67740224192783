import React from 'react';
import { Row, Col, Table } from 'antd';

import { Card } from 'src/components';
import { table } from 'src/utils';

import { IProps } from './table.type';
import { mainColumns, itemsColumns, dataSource } from './table.utils';

const expandedRowRender = (record: any) => {
  return (
    <Table
      rowKey={(record: any) => record.id}
      columns={itemsColumns}
      dataSource={record.items}
      pagination={false}
    />
  );
};

const TableComponent: React.FC<IProps> = (props) => {
  const packages = dataSource;

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Card>
          <Table
            {...table}
            rowKey={(record: any) => record.id}
            className="components-table-demo-nested"
            columns={mainColumns}
            expandable={{ expandedRowRender }}
            dataSource={packages}
            bordered
          />
        </Card>
      </Col>
    </Row>
  );
};

export default TableComponent;
