import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon {
    color: #666;
    font-size: 2rem;
  }
`;

export const Title = styled.h1`
  color: #666;
  font-size: 1rem;
  margin-top: 20px;
`;
