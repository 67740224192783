import styled from 'styled-components';

import { Col as AntdCol } from 'antd';

export const Text = styled.p``;

export const Col = styled(AntdCol)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
