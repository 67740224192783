import React from 'react';
import { Modal, Result, Button, Row, Descriptions, Divider } from 'antd';
import { BsPrinterFill } from 'react-icons/bs';

import { IProps } from './modal.type';
import { Text, Col } from './modal.style';

const ModalComponent: React.FC<IProps> = (props) => {
  const { packageData, visible } = props;

  return (
    <Modal
      title={false}
      footer={false}
      centered
      visible={visible}
      width={650}
      closable={false}
    >
      <Result
        status="success"
        title="Informações cadastradas com sucesso!"
        subTitle={
          <>
            <Text>
              Visualize o resumo das informações abaixo, clique no botão de
              imprimir para visualizar as etiquetas.
            </Text>
            <Divider />
            <Descriptions title={`Pacote Nº ${packageData.id}`}>
              <Descriptions.Item label="Ordem de Corte:" span={2}>
                {packageData.cutOrder}
              </Descriptions.Item>
              <Descriptions.Item label="Pedido" span={1}>
                Nº {packageData.orderId}
              </Descriptions.Item>
              <Descriptions.Item label="Itens Cadastrados:" span={2}>
                {packageData.packageItems.length}
              </Descriptions.Item>
              <Descriptions.Item label="Corte:" span={1}>
                {packageData.cut}
              </Descriptions.Item>
            </Descriptions>
          </>
        }
      >
        <Row gutter={16}>
          <Col span={24} lg={12}>
            <Button
              type="primary"
              onClick={() =>
                props.handlePrint(packageData?.packageItemsPDFUrl || '')
              }
              className="mb-1"
            >
              Imprimir Etiquetas <BsPrinterFill className="ms-2" />
            </Button>
            <small>(Clique para abrir em uma nova aba)</small>
          </Col>

          <Col span={24} lg={12}>
            <Button
              type="default"
              onClick={() => {
                props.handleDuplicate(
                  packageData.orderId,
                  packageData?.packageItemsPDFUrl || ''
                );
              }}
              style={{ borderRadius: 0 }}
            >
              Duplicar e Editar Pacote
            </Button>
            <small>(As etiquetas abrirão em uma nova aba)</small>
          </Col>
        </Row>
      </Result>
    </Modal>
  );
};

export default ModalComponent;
