import { IOrder, IOrderSizes } from 'src/interfaces/order';

export const FETCH_ORDER = 'FETCH_ORDER';
export const FETCH_ORDERS_BY_CUT_ORDER = 'FETCH_ORDERS_BY_CUT_ORDER';
export const FETCH_MEASUREMENTS_FROM_ORDER = 'FETCH_MEASUREMENTS_FROM_ORDER';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';

export interface IReducerOrderState {
  all?: IOrder[];
  current?: IOrder;
  measurements?: IOrderSizes[];
}

export interface IFetchOrder {
  type: typeof FETCH_ORDER;
  payload: IOrder;
}

export interface IFetchOrderByCutOrders {
  type: typeof FETCH_ORDERS_BY_CUT_ORDER;
  payload: IOrder[];
}

export interface ICleanOrders {
  type: typeof CLEAR_ORDERS;
}

export interface IFetchMeasurementsFromOrder {
  type: typeof FETCH_MEASUREMENTS_FROM_ORDER;
  payload: IOrderSizes[];
}

export type OrderActionTypes =
  | IFetchOrder
  | IFetchOrderByCutOrders
  | IFetchMeasurementsFromOrder
  | ICleanOrders;
