import { Dispatch } from 'react';

import OrderService from 'src/services/order.service';
import AuditService from 'src/services/audit.service';

import { IOrder } from 'src/interfaces/order';
import {
  IStepReport,
  IStepReportFilter,
  IPackagesReportFilter,
} from 'src/interfaces/audit';
import { request } from 'src/utils';

import {
  FETCH_ORDER,
  FETCH_ORDERS_BY_CUT_ORDER,
  FETCH_MEASUREMENTS_FROM_ORDER,
  CLEAR_ORDERS,
  FETCH_STEP_REPORT,
  FETCH_PACKAGES_REPORT,
  AuditActionTypes,
} from './types';

export const fetchOrdersByCutOrder =
  (name: string) => async (dispatch: Dispatch<AuditActionTypes>) => {
    try {
      const response: IOrder[] = await OrderService.fetchOrdersByCutOrder(name);

      dispatch({
        type: FETCH_ORDERS_BY_CUT_ORDER,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const fetchMeasurementsFromOrder =
  (orderId: string) => async (dispatch: Dispatch<AuditActionTypes>) => {
    try {
      const response: IOrder = await OrderService.fetchMeasurementsFromOrder(
        orderId
      );

      dispatch({
        type: FETCH_ORDER,
        payload: response,
      });

      dispatch({
        type: FETCH_MEASUREMENTS_FROM_ORDER,
        payload: response.orderSizes,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const fetchStepReport =
  (filter: IStepReportFilter) =>
  async (dispatch: Dispatch<AuditActionTypes>) => {
    try {
      const response: IStepReport[] = await AuditService.fetchStepReport(
        filter
      );

      dispatch({
        type: FETCH_STEP_REPORT,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const fetchPackagesReport =
  (filter: IPackagesReportFilter) =>
  async (dispatch: Dispatch<AuditActionTypes>) => {
    try {
      const response: any[] = await AuditService.fetchPackagesReport(filter);

      dispatch({
        type: FETCH_PACKAGES_REPORT,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const clearOrders =
  () => async (dispatch: Dispatch<AuditActionTypes>) => {
    dispatch({
      type: CLEAR_ORDERS,
    });
  };
