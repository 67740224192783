import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Row, Col, Form, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';

import { Container, Title, GoBack, FormFinish, Card } from 'src/components';
import {
  createPackage,
  fetchPackagesFromOrderByCutOrder,
} from 'src/store/packages/actions';
import { fetchMeasurementsFromOrder } from 'src/store/orders/actions';
import { store, IReduxState } from 'src/store';
import { ICreatePackageInput, IPackage } from 'src/interfaces/package';
import { Notification } from 'src/utils';
import { ITag } from 'src/interfaces/order';

import InformationComponent from './components/information/information.component';
import TableComponent from './components/table/table.component';
import ModalComponent from './components/modal/modal.component';
import { IProps } from './new.type';

const NewScreen: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const user = useSelector((state: IReduxState) => state.auth?.user);
  const [form] = Form.useForm();

  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);

  const [measurements, setMeasurements] = useState<any>([]);
  const [cutNumber, setCutNumber] = useState<number>(0);
  const [tags, setTags] = useState<ITag[]>([]);
  const [packageData, setPackageData] = useState<IPackage | undefined>(
    undefined
  );

  const handlePrint = (printUrl: string) => {
    window.open(`https://${printUrl}`, '_blank');

    window.location.reload();
  };

  const handleDuplicate = async (orderId: string, printUrl: string) => {
    window.open(`https://${printUrl}`, '_blank');

    setPackageData(undefined);

    try {
      await handleMeasurements(orderId);
    } finally {
      form.setFieldsValue({
        cut: cutNumber + 1,
      });
    }
  };

  const handleSubmit = async (values: ICreatePackageInput | any) => {
    setSending(true);

    try {
      const items = store.getState().packages.new?.items;

      values.items = items;

      const data = await props.createPackage(values);

      if (!!data) {
        setPackageData(data);
      }
    } finally {
      setSending(false);
    }
  };

  const handleMeasurements = async (id?: string) => {
    if (!!id) {
      setLoading(true);

      try {
        const res = await props.fetchMeasurementsFromOrder(id);

        const lastPacks = await props.fetchPackagesFromOrderByCutOrder(
          id,
          form.getFieldValue('cutOrder')
        );

        const data: [] = res.orderSizes.reduce(
          (previousValue: any, currentValue: any) => {
            let prev: any = previousValue;

            if (!!currentValue.quantity)
              prev.push(currentValue?.gridSize?.name);

            return prev;
          },
          []
        );

        setMeasurements(data);
        setCutNumber(lastPacks.length + 1 || 1);
        setTags(res.model?.tags || []);

        form.setFieldsValue({
          tags: res.packages?.pop()?.tags.map((item: any) => item.id),
        });
      } catch (e) {
        console.dir(e);
        Notification.error({
          message:
            'Não encontramos tabela de medidas referente à esse pedido nessa ordem de corte.',
        });

        setMeasurements([]);
        setCutNumber(0);
        setTags([]);
      } finally {
        setTimeout(() => setLoading(false), 500);
      }
    } else {
      setMeasurements([]);
      setCutNumber(0);
      setTags([]);
    }
  };

  useEffect(() => {
    if (!user?.canCreate) {
      navigate('/painel/pacotes/');
      Notification.info({
        title: 'Desculpe!',
        message: 'Você não possui permissão para acessar essa página',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Container type="fluid">
      <Row gutter={16} className="mt-4" align="middle" justify="space-between">
        <Col>
          <GoBack color="#444" />
          <Title
            foreword="Pacotes"
            title="Adicionar novos pacotes"
            subtitle="Digite o número da ordem de corte para iniciar o cadastro de pacotes:"
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <InformationComponent
              tags={tags}
              cutNumber={cutNumber}
              form={form}
              handleMeasurements={handleMeasurements}
            />
            {loading ? (
              <Card>
                <Skeleton active />
              </Card>
            ) : !!measurements?.length ? (
              <TableComponent sizes={measurements} />
            ) : (
              !!cutNumber && (
                <Card>
                  Não há grades de medidas ativas relacionadas a esse pedido
                  nesta ordem de corte, selecione outro pedido ou uma nova ordem
                  de corte e tente novamente.
                </Card>
              )
            )}
            <FormFinish sending={sending} />
          </Form>
        </Col>
      </Row>
      {!!packageData && (
        <ModalComponent
          visible={!!packageData}
          handlePrint={handlePrint}
          handleDuplicate={handleDuplicate}
          packageData={packageData}
        />
      )}
    </Container>
  );
};

export default connect(undefined, {
  createPackage,
  fetchMeasurementsFromOrder,
  fetchPackagesFromOrderByCutOrder,
})(NewScreen);
