import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Row, Col, Button } from 'antd';

import { Container, Title, GoBack } from 'src/components';
import { IFilterPackageInput } from 'src/interfaces/package';
import { IReduxState } from 'src/store';
import { fetchPackages } from 'src/store/packages/actions';
import { Notification } from 'src/utils';

import { IProps } from './package.type';
import FilterComponent from './components/filter/filter.component';
import TableComponent from './components/table/table.component';
import PaginationComponent from './components/pagination/pagination.component';

const PackageScreen: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const user = useSelector((state: IReduxState) => state.auth?.user);

  const { packages, filter } = props;

  const [loading, setLoading] = useState(false);

  const handleSearch = async (
    filter?: IFilterPackageInput,
    pageNumber?: number
  ) => {
    setLoading(true);

    try {
      await props.fetchPackages(filter, pageNumber);
    } catch (e) {
      Notification.error({ message: 'Ocorreu um erro ao buscar os cortes' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container type="fluid">
      <Row gutter={16} className="mt-4" align="middle" justify="space-between">
        <Col>
          <GoBack color="#444" />
          <Title
            foreword="Pacotes"
            title="Ver todos os Cortes"
            subtitle="Clique no ícone + para ver mais informações"
          />
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => navigate('/painel/pacotes/novo')}
            size="large"
            disabled={!user?.canCreate}
          >
            Adicionar Novo
          </Button>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <FilterComponent loading={loading} handleSearch={handleSearch} />
        </Col>
        <Col span={24}>
          <TableComponent
            loading={loading}
            dataSource={packages?.data}
            handleSearch={handleSearch}
            filter={filter}
            currentPage={Number(packages?.meta.currentPage) || 1}
          />
        </Col>
        <Col span={24}>
          <PaginationComponent
            pagesMeta={packages?.meta}
            handleSearch={handleSearch}
            filter={filter}
          />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  packages: state.packages.all,
  filter: state.packages.filter,
});

export default connect(mapStateToProps, { fetchPackages })(PackageScreen);
