export const wordWrap = (text: string, number: number) => {
  let data: string = text;

  if (text.length > number) {
    data = text.substring(0, number) + '[...]';
  }

  return data;
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};
