import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { IReducerStepsState, FETCH_STEPS, StepsActionTypes } from './types';

const initialState: IReducerStepsState = {
  all: undefined,
};

const persistConfig = {
  storage,
  key: 'steps',
  whitelist: ['steps'],
};

const reducers = (state = initialState, action: StepsActionTypes) => {
  switch (action.type) {
    case FETCH_STEPS:
      return {
        ...state,
        all: action.payload,
      };
    default:
      return state;
  }
};

export default persistReducer(persistConfig, reducers);
