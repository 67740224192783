import styled from 'styled-components';
import { Button as AntButton } from 'antd';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Item = styled.div`
  display: flex;
  a {
    height: 16px;
  }
`;

export const Button = styled(AntButton)`
  display: inline-flex;
  align-items: center;
  padding: 0 3px;
`;

const Default = {
  editIcon: {
    color: '#FB8C00',
    height: 16,
  } as React.CSSProperties,
  removeIcon: {
    color: '#f44336',
    height: 16,
  } as React.CSSProperties,
  viewIcon: {
    color: 'var(--button-table-actions)',
    height: 16,
  } as React.CSSProperties,
  copyIcon: {
    color: `var(--button-table-actions)`,
    height: 16,
  } as React.CSSProperties,
}

export default Default;
