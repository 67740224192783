import React, { useState } from 'react';
import { Row, Col, Form, Input, Button } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Card } from 'src/components';
import LoginIcon from 'src/assets/icons/login-gradient.png';
import { Notification } from 'src/utils';
import { signIn } from 'src/store/auth/actions';

import { IProps } from './auth.type';
import { rules } from './auth.utils';
import { Wrapper, Title, Image, Subtitle } from './auth.style';

const AuthScreen: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const [sending, setSending] = useState(false);

  const handleSignIn = async (values: any) => {
    setSending(true);

    try {
      const response = await props.signIn(values);

      if (!!response) {
        navigate('/painel/pacotes/');
      }
    } catch {
      Notification.error({
        message: 'Ocorreu um erro tente novamente mais tarde.',
      });
    } finally {
      setSending(false);
    }
  };

  return (
    <>
      <Wrapper>
        <div className="py-5" style={{ width: '100%' }}>
          <Row justify="center" align="middle">
            <Col xs={20} sm={20} md={14} lg={8} xxl={6}>
              <Card>
                <Image src={LoginIcon} alt="login" />
                <Title>Login</Title>
                <Subtitle>Informe suas credenciais abaixo para entrar</Subtitle>
                <Form onFinish={handleSignIn} layout="vertical">
                  <Form.Item label="E-mail" name="email" rules={rules.email}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Senha"
                    name="password"
                    rules={rules.password}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Row justify="center" align="middle" className="mb-3">
                    <a href="/">Esqueceu sua senha?</a>
                  </Row>

                  <Row gutter={16} justify="space-around" align="middle">
                    <Button type="primary" htmlType="submit" loading={sending}>
                      Entrar
                    </Button>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </Wrapper>
    </>
  );
};

export default connect(undefined, { signIn })(AuthScreen);
