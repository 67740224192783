import { Route } from 'react-router-dom';

import { PrivateRoute } from './user.utils';

import packagesRouteGroup from './groups/packages.routes';

import MainScreen from 'src/screens/main/main.component';

const userRoutes = (
  <Route path="painel">
    <Route index element={<PrivateRoute children={<MainScreen />} />} />
    {packagesRouteGroup}
  </Route>
);

export default userRoutes;
