import React, {
  useState,
  createContext,
  useRef,
  useContext,
  useEffect,
} from 'react';
import { Form, Button, Row, Col, Table, FormInstance, InputNumber } from 'antd';

import { table } from 'src/utils';
import { Card, FormFinish } from 'src/components';

import {
  IProps,
  IEditableCellProps,
  IEditableRowProps,
  TDataType,
} from './table.type';

import { updateRowsSum, initialValues, dataColumns } from './table.utils';

const EditableContext = createContext<FormInstance<any> | null>(null);

const EditableRow: React.FC<IEditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell: React.FC<IEditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<any>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } finally {
      return;
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[{ required: true }]}
      >
        <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} min={0} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

const EditSkillsComponent: React.FC<IProps> = (props) => {
  const { packageItem, sending } = props;

  const [sum, setSum] = useState(
    packageItem.quantity - Math.trunc(packageItem.quantity / 2) * 2
  );
  const [rows, setRows]: any = useState(initialValues(packageItem));
  const [count, setCount]: any = useState(rows.length || 0);

  const handleDelete = (key: React.Key) => {
    const data = rows?.filter((item: any) => item.key !== key);
    setRows(data);
    setSum(updateRowsSum(packageItem, data));
  };

  const handleAdd = () => {
    const data: TDataType = {
      key: count,
      quantity: sum,
    };

    setRows([...rows, data]);
    setSum(updateRowsSum(packageItem, [...rows, data]));
    setCount(count + 1);
  };

  const handleSave = (row: TDataType) => {
    const data = [...rows];

    const index = data.findIndex((item) => row.key === item.key);
    const item = data[index];

    data.splice(index, 1, {
      ...item,
      ...row,
    });

    setRows(data);
    setSum(updateRowsSum(packageItem, data));
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = dataColumns(handleDelete).map((col: any) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: TDataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  return (
    <Col span={24}>
      <Form
        layout="vertical"
        onFinish={() => props.handleSubmit(packageItem, rows)}
        className="pt-2"
      >
        <Card className="mt-0">
          <Row gutter={16} justify="space-between" align="middle">
            <div className="ms-2">
              <h4>
                <b>Quantidade Total:</b> {packageItem.quantity}
              </h4>
              <h4>
                <b>Peças restantes:</b>{' '}
                {sum < 0 ? (
                  <span style={{ color: 'red' }}>
                    Você execedeu o limite de peças!
                  </span>
                ) : (
                  sum
                )}
              </h4>
            </div>

            <Button
              onClick={handleAdd}
              type="primary"
              className="mb-4 me-2"
              size="small"
            >
              Fragmentar
            </Button>
            <Col span={24}>
              <Table
                {...table}
                pagination={false}
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={rows}
                columns={columns}
              />
            </Col>
          </Row>
        </Card>

        <FormFinish
          sending={sending}
          disabled={!!sum}
          message="As informações só poderão ser salvas se o número de peças restantes for igual a 0."
        />
      </Form>
    </Col>
  );
};

export default EditSkillsComponent;
