import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'antd';

import { Container, Title } from 'src/components';

import { IProps } from './main.type';

import PresentationComponent from './components/presentation/presentation.component';
import ChartsComponent from './components/charts/charts.component';
import TableComponent from './components/table/table.component';

const MainScreen: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  return (
    <Container type="fluid">
      <Row gutter={16} justify="space-between">
        <Col span={24}>
          <Row
            gutter={16}
            className="mt-5"
            align="middle"
            justify="space-between"
          >
            <Col>
              <Title
                foreword="Painel"
                title="Bem-vindo ao Boilerplate"
                subtitle="Navegue pelo menu ao lado para visualizar a demo."
              />
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => navigate('/painel/pacotes/')}
                size="large"
              >
                Ver Pacotes
              </Button>
            </Col>
          </Row>
          <Row gutter={16}>
            <PresentationComponent />
            <ChartsComponent />
          </Row>
          <TableComponent />
        </Col>
      </Row>
    </Container>
  );
};

export default MainScreen;
