import React from 'react';
import { Col } from 'antd';

import { Card } from 'src/components';

import { IProps } from './presentation.type';

const PresentationComponent: React.FC<IProps> = (props) => {
  return (
    <>
      <Col span={24} lg={10}>
        <Card title="Aviso">
          <p>
            <b>Atenção: </b>
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam"
          </p>
        </Card>
        <Card
          title="Informativos"
          subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        >
          <p>
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat."
          </p>
        </Card>
      </Col>
    </>
  );
};

export default PresentationComponent;
