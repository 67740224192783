import { IStepReport } from 'src/interfaces/audit';
import { IOrder, IOrderSizes } from 'src/interfaces/order';

export const FETCH_ORDER = 'FETCH_ORDER';
export const FETCH_ORDERS_BY_CUT_ORDER = 'FETCH_ORDERS_BY_CUT_ORDER';
export const FETCH_MEASUREMENTS_FROM_ORDER = 'FETCH_MEASUREMENTS_FROM_ORDER';
export const FETCH_STEP_REPORT = 'FETCH_STEP_REPORT';
export const FETCH_PACKAGES_REPORT = 'FETCH_PACKAGES_REPORT';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';

export interface IReducerAuditState {
  orders?: IOrder[];
  currentOrder?: IOrder;
  measurements?: IOrderSizes[];
  packagesReport?: any[];
  stepReport?: IStepReport[];
}

export interface IFetchOrder {
  type: typeof FETCH_ORDER;
  payload: IOrder;
}

export interface IFetchOrderByCutOrders {
  type: typeof FETCH_ORDERS_BY_CUT_ORDER;
  payload: IOrder[];
}

export interface ICleanOrders {
  type: typeof CLEAR_ORDERS;
}

export interface IFetchMeasurementsFromOrder {
  type: typeof FETCH_MEASUREMENTS_FROM_ORDER;
  payload: IOrderSizes[];
}

export interface IFetchStepReport {
  type: typeof FETCH_STEP_REPORT;
  payload: IStepReport[];
}

export interface IFetchPackagesReport {
  type: typeof FETCH_PACKAGES_REPORT;
  payload: any[];
}

export type AuditActionTypes =
  | IFetchOrder
  | IFetchOrderByCutOrders
  | IFetchMeasurementsFromOrder
  | IFetchStepReport
  | IFetchPackagesReport
  | ICleanOrders;
