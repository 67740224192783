import React from 'react';
import { Pagination } from 'antd';

import { Card } from 'src/components';

import { IProps } from './pagination.type';

const PaginationComponent: React.FC<IProps> = (props) => {
  const { filter, pagesMeta, handleSearch } = props;

  return !!pagesMeta ? (
    <Card className="mt-0">
      <Pagination
        defaultCurrent={1}
        total={pagesMeta.totalItems}
        current={pagesMeta.currentPage}
        pageSize={pagesMeta.itemsPerPage}
        onChange={(e) => handleSearch(filter, e)}
      />
    </Card>
  ) : null;
};

export default PaginationComponent;
