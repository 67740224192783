import React from 'react';

import { IProps } from './card.type';
import { Container, CardBody } from './card.style';
import HeaderComponent from './components/header/header.component';

const CardComponent: React.FC<IProps> = (props) => {
  const { title, subtitle, children, button, required } = props;

  return (
    <Container
      style={props.style}
      className={props.className}
      type={props.type}
    >
      {!!title && (
        <HeaderComponent
          title={title}
          subtitle={subtitle}
          button={button}
          required={required}
        />
      )}

      <CardBody className="card-body">{children}</CardBody>
    </Container>
  );
};

export default CardComponent;
