import { Tooltip, Tag, Typography, Badge, Popconfirm } from 'antd';
import { FaTrashRestoreAlt } from 'react-icons/fa';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import moment from 'moment';

export const columns = (handleRestore: any) =>
  [
    {
      title: (
        <AiOutlineInfoCircle size={20} style={{ verticalAlign: 'middle' }} />
      ),
      dataIndex: ['active'],
      key: 'active',
      render: (text: any) =>
        text ? (
          <Tooltip title="Ativo">
            <Badge status="success" className="ms-2" />
          </Tooltip>
        ) : (
          <Tooltip title="Inativo">
            <Badge status="default" className="ms-2" />
          </Tooltip>
        ),
    },
    {
      title: 'Pacote (ID)',
      dataIndex: ['id'],
      key: 'id',
      shouldCellUpdate: (record: any, prevRecord: any) =>
        !(record === prevRecord),
    },
    {
      title: 'OC',
      dataIndex: ['package', 'cutOrder'],
      key: 'cutOrder',
      render: (text: any) => <span>Nº {text}</span>,
    },
    {
      title: 'Tam',
      dataIndex: ['orderSize', 'gridSize', 'name'],
      key: 'orderSizeId',
      render: (text: any) => <Tag color="#4177b2">{text}</Tag>,
    },
    {
      title: 'Qtde',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text: any) => (
        <Tag color="#53aa91">
          <b>{text}</b>
        </Tag>
      ),
    },
    {
      title: 'Lote',
      dataIndex: ['batch'],
      key: 'batch',
    },
    {
      title: 'Atualizado em',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: any) => moment(text).format('DD/MM/Y HH:mm '),
      shouldCellUpdate: (record: any, prevRecord: any) =>
        !(record === prevRecord),
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (text: any, record: any) => (
        <span>
          <Tooltip title="Restaurar">
            <Popconfirm
              style={{ minWidth: 120 }}
              title="Tem certeza?"
              onConfirm={() => handleRestore(record.id)}
            >
              <Typography.Link style={{ cursor: 'pointer' }}>
                <FaTrashRestoreAlt size={18} />
              </Typography.Link>
            </Popconfirm>
          </Tooltip>
        </span>
      ),
    },
  ] as any;
