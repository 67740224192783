import { Badge, Avatar, Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import Icon from 'src/assets/icons/icon.svg';

import { wordWrap } from 'src/utils';

export const mainColumns = [
  {
    title: 'Pacote (#)',
    dataIndex: 'number',
    key: 'number',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text: any) => (
      <span>
        <Badge color="green" />
        <h5 className="d-inline-flex mb-0" style={{ letterSpacing: 1 }}>
          <b>{text}</b>
        </h5>
      </span>
    ),
  },
  {
    title: 'Responsável',
    dataIndex: 'responsable',
    key: 'responsable',
    render: (text: any, record: any) => (
      <Tooltip title="John Doe">
        <div style={{ cursor: 'pointer' }}>
          <Avatar size="small" src={Icon} />
          <h5 className="d-inline-flex ms-2" style={{ letterSpacing: 1 }}>
            <b>{wordWrap(record.responsable.name, 18)}</b>
          </h5>
        </div>
      </Tooltip>
    ),
  },
  {
    title: 'Ações',
    dataIndex: 'actions',
    key: 'actions',
    render: (text: any, record: any) => (
      <Tooltip title="Ver mais">
        <Link to="/">Ver mais</Link>
      </Tooltip>
    ),
  },
] as any;

export const itemsColumns = [
  {
    title: 'Qtd.',
    dataIndex: ['quantity'],
    key: 'quantity',
  },
  {
    title: 'Item',
    dataIndex: ['name'],
    key: 'name',
  },
  {
    title: 'Status',
    dataIndex: ['status'],
    key: 'status',
    render: (text: any) => (
      <>
        <Badge color="red" />
        <h5 className="d-inline-flex mb-0" style={{ letterSpacing: 1 }}>
          <b>{text}</b>
        </h5>
      </>
    ),
  },
] as any;

export const dataSource: any = [
  {
    id: 1,
    number: '100527',
    status: 'Solicitação Iniciada',
    responsable: {
      name: 'Mary Doe',
    },
    items: [
      {
        id: 2,
        name: 'Item 01',
        quantity: 1,
        status: 'Entregue',
      },
      {
        id: 1,
        name: 'Item 02',
        quantity: 19,
        status: 'Em progresso',
      },
      {
        id: 3,
        name: 'Item 03',
        quantity: 20,
        status: 'Cancelado',
      },
    ],
  },
  {
    id: 3,
    number: '100525',
    status: 'Solicitação Finalizada',
    responsable: {
      name: 'John Doe',
    },
    items: [
      {
        id: 2,
        name: 'Item 01',
        quantity: 1,
        status: 'Entregue',
      },
      {
        id: 1,
        name: 'Item 02',
        quantity: 19,
        status: 'Em progresso',
      },
      {
        id: 3,
        name: 'Item 03',
        quantity: 20,
        status: 'Cancelado',
      },
    ],
  },
  {
    id: 4,
    number: '100525',
    status: 'Solicitação Finalizada',
    responsable: {
      name: 'John Doe',
    },
    items: [
      {
        id: 2,
        name: 'Item 01',
        quantity: 1,
        status: 'Entregue',
      },
      {
        id: 1,
        name: 'Item 02',
        quantity: 19,
        status: 'Em progresso',
      },
      {
        id: 3,
        name: 'Item 03',
        quantity: 20,
        status: 'Cancelado',
      },
    ],
  },
];
