import LogoBaseWhite from 'src/assets/images/logo-white.svg';
import LogoBaseBlack from 'src/assets/images/logo-black.svg';

import IconBase from 'src/assets/icons/icon.svg';

import EnglishFlag from 'src/assets/icons/lang/en.png';
import PortugueseFlag from 'src/assets/icons/lang/pt.png';

export const logoBase = {
  logo: {
    white: LogoBaseWhite,
    black: LogoBaseBlack,
  },
};

export const iconsBase = {
  icons: {
    default: IconBase,
  },
};

export const flagIcons = {
  'en-US': EnglishFlag,
  'pt-BR': PortugueseFlag,
} as any;

const images = {
  ...logoBase,
  ...iconsBase,
  ...flagIcons,
};

export default images;
