import { api } from './';

const OrderService = {
  current: async (id: string) => {
    try {
      const res = await api.get(`/orders/${id}`);
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  fetchOrdersByCutOrder: async (name: string) => {
    try {
      const res = await api.get(`/cut_orders/${name}/orders`);
      return res.data.orders;
    } catch (e) {
      throw e;
    }
  },
  fetchMeasurementsFromOrder: async (orderId: string) => {
    try {
      const res = await api.get(`/orders/${orderId}/details`);
      return res.data;
    } catch (e) {
      console.dir(e);
      throw e;
    }
  },
};

export default OrderService;
