import { combineReducers } from 'redux';

import configReducer from './config/reducers';
import authReducer from './auth/reducers';
import packagesReducer from './packages/reducers';
import ordersReducer from './orders/reducers';
import stepsReducer from './steps/reducers';
import auditReducer from './audit/reducers';

export default combineReducers({
  config: configReducer,
  auth: authReducer,
  packages: packagesReducer,
  orders: ordersReducer,
  steps: stepsReducer,
  audit: auditReducer,
});
