import React from 'react';
import { Wrapper, First } from './wrapper.style';

const WrapperComponent: React.FC = () => (
  <Wrapper>
    <First />
  </Wrapper>
);

export default WrapperComponent;
