import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #191919;
  color: #e4d9d9;
  font-size: 9px;
  font-weight: 400;
  padding: 16px 0;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  letter-spacing: 2px;
`;
