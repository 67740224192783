import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { IPaginatePackages } from 'src/interfaces/package';

import {
  IReducerPackagesState,
  FETCH_PACKAGE,
  FETCH_PACKAGES,
  FETCH_PACKAGE_ITEM,
  FETCH_PACKAGES_FROM_ORDER_BY_CUT_ORDER,
  FETCH_ERASED_PACKAGE_ITEMS,
  UPDATE_FILTER_ERASED_PACKAGES,
  UPDATE_TABLE_ITEM,
  UPDATE_FILTER_PACKAGES,
  UPDATE_PACKAGES,
  PackagesActionTypes,
} from './types';

const initialState: IReducerPackagesState = {
  all: undefined,
  filter: {
    cutOrderName: '',
    orderNumber: '',
    packageItemId: '',
  },
  new: {
    items: [],
    packages: [],
  },
  current: undefined,
  currentItem: undefined,
  erased: undefined,
  erasedFilter: {
    cutOrderName: '',
    packageItemId: '',
  },
};

const persistConfig = {
  storage,
  key: 'packages',
  whitelist: ['all', 'erased'],
};

const reducers = (state = initialState, action: PackagesActionTypes) => {
  switch (action.type) {
    case FETCH_PACKAGES:
      return {
        ...state,
        all: action.payload,
      };
    case FETCH_PACKAGE:
      return {
        ...state,
        current: action.payload,
      };
    case FETCH_PACKAGE_ITEM:
      return {
        ...state,
        currentItem: action.payload,
      };
    case FETCH_PACKAGES_FROM_ORDER_BY_CUT_ORDER:
      return {
        ...state,
        new: {
          ...state.new,
          items: state.new?.items,
          packages: action.payload,
        },
      };
    case FETCH_ERASED_PACKAGE_ITEMS:
      return {
        ...state,
        erased: action.payload,
      };
    case UPDATE_FILTER_ERASED_PACKAGES:
      return {
        ...state,
        erasedFilter: action.payload,
      };
    case UPDATE_FILTER_PACKAGES:
      return {
        ...state,
        filter: action.payload,
      };
    case UPDATE_TABLE_ITEM:
      return {
        ...state,
        new: {
          ...state.new,
          packages: state.new?.packages,
          items: action.payload,
        },
      };
    case UPDATE_PACKAGES:
      return {
        ...state,
        all: {
          ...state.all,
          items: action.payload,
        } as IPaginatePackages,
      };
    default:
      return state;
  }
};

export default persistReducer(persistConfig, reducers);
