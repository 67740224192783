import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { IReduxState } from 'src/store';
import { LOGOUT_USER } from 'src/store/auth/types';
// import { scrollToTop } from 'src/utils';

interface IProps {
  children: React.ReactElement<any, any> | null;
}

export const PrivateRoute: React.FC<IProps> = (props) => {
  // scrollToTop();

  const [cookies] = useCookies();

  const token = !!useSelector((state: IReduxState) => state.auth?.token);
  const cookieToken = !!cookies?._jwtToken;

  const hasAccess = token && cookieToken;

  const dispatch = useDispatch();

  if (hasAccess) {
    return props.children;
  } else {
    dispatch({ type: LOGOUT_USER });
    return <Navigate to="/" replace />;
  }
};
