import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Result, Button, Row } from 'antd';
import { BsPrinterFill } from 'react-icons/bs';

import { IProps } from './modal.type';
import { Text, Col } from './modal.style';

const ModalComponent: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const { dataSource } = props;

  const handlePrint = (printUrl: string) => {
    window.open(`https://${printUrl}`, '_blank');

    navigate(-1);
  };

  return (
    <Modal
      title={false}
      footer={false}
      centered
      visible={!!dataSource?.childrenPackageItemsPDFUrl}
      width={650}
      closable={false}
    >
      <Result
        status="success"
        title="Informações cadastradas com sucesso!"
        subTitle={
          <>
            <Text>
              Clique no botão de imprimir para visualizar as etiquetas.
            </Text>
          </>
        }
      >
        <Row gutter={16}>
          <Col span={24} lg={12}>
            <Button
              type="primary"
              onClick={() =>
                handlePrint(dataSource?.childrenPackageItemsPDFUrl || '')
              }
              className="mb-1"
            >
              Imprimir Etiquetas <BsPrinterFill className="ms-2" />
            </Button>
            <small>(Clique para abrir em uma nova aba)</small>
          </Col>
        </Row>
      </Result>
    </Modal>
  );
};

export default ModalComponent;
