import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { Cookies } from 'react-cookie';

import { store } from 'src/store';

export const api = axios.create({
  baseURL:
    process.env.REACT_APP_ENV_API_KEY === 'production'
      ? 'https://api.vestsurf.com'
      : 'https://dev-api.vestsurf.com/',
});

api.interceptors.response.use((response: AxiosResponse): any => {
  const cookies = new Cookies();

  const token = store.getState().auth.token;
  const cookieToken = cookies.get('_jwtToken');

  const hasAccess = !!token && !!cookieToken;
  const hasPartialAccess = !token && !!cookieToken;

  try {
    if (hasAccess) {
      response.headers.Authorization = `Bearer ${token}`;
    } else if (hasPartialAccess) {
      response.headers.Authorization = `Bearer ${cookieToken}`;
    }

    return response;
  } catch (error) {
    console.dir(error);
  }
});

api.interceptors.request.use((config: AxiosRequestConfig | any): any => {
  try {
    const cookies = new Cookies();

    const token = store.getState().auth.token;
    const cookieToken = cookies.get('_jwtToken');

    const hasAccess = !!token && !!cookieToken;
    const hasPartialAccess = !token && !!cookieToken;

    if (hasAccess) {
      config.headers.Authorization = `Bearer ${token}`;
    } else if (hasPartialAccess) {
      config.headers.Authorization = `Bearer ${cookieToken}`;
    }

    return config;
  } catch (error) {
    console.dir(error);
  }
});
