import React from 'react';
import { Button } from 'antd';

import { Card } from 'src/components';

import { IProps } from './form-finish.type';

const FormFinish: React.FC<IProps> = (props) => {
  const { formID, sending, disabled, message } = props;

  return (
    <Card>
      <Button
        loading={sending}
        htmlType="submit"
        form={formID}
        type="primary"
        disabled={disabled}
      >
        Salvar
      </Button>
      {message && (
        <small>
          <b>Obs: </b>
          {message}
        </small>
      )}
    </Card>
  );
};

export default FormFinish;
