import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { Row, Col, Skeleton } from 'antd';

import { Container, Title, GoBack, Card } from 'src/components';
import { IPackageItem } from 'src/interfaces/package';
import {
  fetchPackageItem,
  createPackageItemChildren,
} from 'src/store/packages/actions';
import { Notification } from 'src/utils';
import { IReduxState } from 'src/store';

import InformationComponent from './components/information/information.component';
import TableComponent from './components/table/table.component';
import ModalComponent from './components/modal/modal.component';
import { TProps } from './split.type';
import { IUser } from 'src/interfaces/user';

const SplitScreen: React.FC<TProps> = (props) => {
  const navigate = useNavigate();
  const user: IUser = useSelector(
    (state: IReduxState) => state.auth?.user
  );

  const [sending, setSending] = useState(false);
  const [packageItem, setPackageItem] = useState<IPackageItem | undefined>(
    undefined
  );

  const { packageItemId }: any = useParams<string>();

  const handlePackageItem = useCallback(async () => {
    try {
      const data: IPackageItem = await props.fetchPackageItem(packageItemId);
      setPackageItem(data);
    } catch (error) {
      Notification.error({
        title: 'Oops!',
        message: 'Desculpe, não encontramos o pacote.',
      });
      navigate(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (item: IPackageItem, values: any) => {
    setSending(true);

    try {
      const data = await props.createPackageItemChildren(item, values);

      if (!!data) {
        Notification.success({
          title: 'Sucesso',
          message: 'Pacote atualizado com sucesso',
        });

        setPackageItem(data);
      }
    } finally {
      setSending(false);
    }
  };

  useEffect(() => {
    if (!user?.canEdit) {
      navigate('/painel/pacotes/');
      return Notification.info({
        title: 'Desculpe!',
        message: 'Você não possui permissão para acessar essa página',
      });
    }

    handlePackageItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container type="fluid">
      <Row gutter={16} className="mt-4" align="middle" justify="space-between">
        <Col>
          <GoBack color="#444" />
          <Title
            foreword="Pacotes"
            title="Fragmentar pacote"
            subtitle="Você pode dividir o pacote em quantas vezes for necessário, adicione uma nova linha e informe a quantidade."
          />
        </Col>
      </Row>

      <Row>
        {!!packageItem ? (
          <>
            <InformationComponent packageItem={packageItem} />
            <TableComponent
              packageItem={packageItem}
              handleSubmit={handleSubmit}
              sending={sending}
            />
            <ModalComponent dataSource={packageItem} />
          </>
        ) : (
          <Card>
            <Skeleton active />
          </Card>
        )}
      </Row>
    </Container>
  );
};

export default connect(undefined, {
  fetchPackageItem,
  createPackageItemChildren,
})(SplitScreen);
