import { Tooltip, Tag, Typography, Badge, Popover, Popconfirm } from 'antd';

import { ImQrcode } from 'react-icons/im';
import { IoDuplicateOutline } from 'react-icons/io5';
import { FcCancel, FcCheckmark } from 'react-icons/fc';
import { BsTrashFill } from 'react-icons/bs';
import { AiTwotoneEdit, AiOutlineInfoCircle } from 'react-icons/ai';
import moment from 'moment';
import { IUser } from 'src/interfaces/user';

const urlBase =
  process.env.REACT_APP_ENV_API_KEY === 'production'
    ? 'https://api.vestsurf.com'
    : 'https://dev-api.vestsurf.com';

interface IProps {
  isEditing: (record: any) => boolean;
  editingKey: any;
  edit: (record: Partial<any> & { key: React.Key }) => void;
  save: (key: React.Key) => void;
  cancel: (key: React.Key | any) => void;
}

export const mainColumns = (user: IUser) =>
  [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'OC',
      dataIndex: 'cutOrder',
      key: 'cutOrder',
      render: (text: string) => `Nº ${text}`,
    },
    {
      title: 'Pedido',
      dataIndex: ['order', 'orderNumber'],
      key: 'orderNumber',
      render: (text: number) => `${text}`,
    },
    {
      title: 'Corte',
      dataIndex: 'cut',
      key: 'cut',
    },
    {
      title: 'Criado em',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: any) => moment(text).format('DD/MM/Y HH:mm '),
    },
    {
      title: 'QRCode',
      dataIndex: 'actions',
      key: 'actions',
      render: (text: any, record: any) => (
        <Tooltip title="Imprimir">
          <Typography.Link
            disabled={!user?.canPrint}
            href={`${urlBase}/packages/${record.id}/qr_codes.pdf`}
            target="_blank"
            rel="noreferrer"
          >
            <ImQrcode size={18} alignmentBaseline="middle" />
          </Typography.Link>
        </Tooltip>
      ),
    },
  ] as any;

export const itemsColumns = (props: IProps, handleRemove: any, user: IUser) =>
  [
    {
      title: (
        <AiOutlineInfoCircle size={20} style={{ verticalAlign: 'middle' }} />
      ),
      dataIndex: ['active'],
      key: 'active',
      render: (text: any) =>
        text ? (
          <Tooltip title="Ativo">
            <Badge status="success" className="ms-2" />
          </Tooltip>
        ) : (
          <Tooltip title="Inativo">
            <Badge status="default" className="ms-2" />
          </Tooltip>
        ),
      shouldCellUpdate: (record: any, prevRecord: any) =>
        !(record === prevRecord),
    },
    {
      title: 'Derivações',
      dataIndex: ['childrenPackageItems'],
      key: 'childrenPackageItems',
      render: (text: any) =>
        !!text?.length ? (
          <Popover
            content={
              <div>
                {text?.map((item: any) => (
                  <Tag>ID: {item.id}</Tag>
                ))}
              </div>
            }
          >
            Sim
          </Popover>
        ) : (
          'Não'
        ),
      shouldCellUpdate: (record: any, prevRecord: any) =>
        !(record === prevRecord),
    },
    {
      title: 'Pacote (ID)',
      dataIndex: ['id'],
      key: 'id',
      shouldCellUpdate: (record: any, prevRecord: any) =>
        !(record === prevRecord),
    },
    {
      title: 'Tam',
      dataIndex: ['orderSize', 'gridSize', 'name'],
      key: 'orderSizeId',
      render: (text: any) => <Tag color="#4177b2">{text}</Tag>,
      shouldCellUpdate: (record: any, prevRecord: any) =>
        !(record === prevRecord),
    },
    {
      title: 'Qtde',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text: any) => (
        <Tag color="#53aa91">
          <b>{text}</b>
        </Tag>
      ),
      editable: true,
    },
    {
      title: 'Lote',
      dataIndex: ['batch'],
      key: 'batch',
      shouldCellUpdate: (record: any, prevRecord: any) =>
        !(record === prevRecord),
    },
    {
      title: 'Atualizado em',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: any) => moment(text).format('DD/MM/Y HH:mm '),
      shouldCellUpdate: (record: any, prevRecord: any) =>
        !(record === prevRecord),
    },
    {
      title: 'QRCode',
      dataIndex: 'qrcode',
      key: 'qrcode',
      render: (text: any, record: any) =>
        record.active ? (
          <Tooltip title="Imprimir">
            <Typography.Link
              disabled={!user?.canPrint}
              href={`${urlBase}/package_items/${record.id}/qr_codes.pdf`}
              target="_blank"
              rel="noreferrer"
            >
              <ImQrcode size={18} alignmentBaseline="middle" />
            </Typography.Link>
          </Tooltip>
        ) : (
          <b>Inativo</b>
        ),
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      render: (_: any, record: any) => {
        const editable = props.isEditing(record);

        return editable ? (
          <span>
            <Typography.Link
              onClick={() => props.save(record.id)}
              style={{ marginRight: 8, cursor: 'pointer' }}
            >
              <FcCheckmark size={18} />
            </Typography.Link>
            <Typography.Link
              onClick={props.cancel}
              style={{ marginRight: 8, cursor: 'pointer' }}
            >
              <FcCancel size={18} />
            </Typography.Link>
          </span>
        ) : record.active ? (
          <span>
            <Tooltip title="Editar">
              <Typography.Link
                disabled={
                  props.editingKey !== '' || !record.active || !user?.canEdit
                }
                onClick={() => props.edit(record)}
                style={{ marginRight: 8, cursor: 'pointer' }}
              >
                <AiTwotoneEdit size={18} />
              </Typography.Link>
            </Tooltip>
            <Tooltip title="Fragmentar Pacote">
              <Typography.Link
                disabled={!user?.canEdit}
                href={`/painel/pacotes/item/${record.id}/fragmentar`}
                style={{ marginRight: 8, cursor: 'pointer' }}
              >
                <IoDuplicateOutline size={18} alignmentBaseline="middle" />
              </Typography.Link>
            </Tooltip>
            <Tooltip title="Lixeira">
              <Popconfirm
                style={{ minWidth: 120 }}
                title="Tem certeza?"
                onConfirm={() => handleRemove(record.id)}
              >
                <Typography.Link
                  disabled={!user?.canDelete}
                  style={{ marginLeft: 8, cursor: 'pointer' }}
                >
                  <BsTrashFill size={18} />
                </Typography.Link>
              </Popconfirm>
            </Tooltip>
          </span>
        ) : (
          <b>Inativo</b>
        );
      },
    },
  ] as any;
