import React from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import { AiOutlineHome, AiOutlineLogout } from 'react-icons/ai';
import {
  BsBoxSeam,
  BsClipboardPlus,
  BsTrash,
  BsClipboardCheck,
} from 'react-icons/bs';
import { IoReturnDownBackOutline } from 'react-icons/io5';

import { IReduxState } from 'src/store';
import { logout } from 'src/store/auth/actions';
import { Notification, images } from 'src/utils';

import Divider from './components/divider/divider.component';
import { IProps } from './sider.type';
import { LogoWide, LogoIcon } from './sider.style';

const LayoutSider: React.FC<IProps> = (props) => {
  const navigate = useNavigate();

  const { collapsed, user } = props;

  const handleLogout = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | any
  ) => {
    props.logout();
  };

  const collapsedTitle = (text: string, collapsed: boolean) => {
    if (!collapsed) {
      return text;
    }
    return;
  };

  return (
    <>
      <Link to="/">
        <LogoIcon
          src={images.icons.default}
          alt="logo"
          title="logo"
          width="45px"
          collapsed={collapsed}
        />
        <LogoWide
          src={images.logo.white}
          alt="logo"
          title="logo"
          width="80%"
          collapsed={collapsed}
        />
      </Link>

      <Menu theme="dark" mode="inline">
        <Divider content={collapsedTitle('Dashboard', collapsed)} noBorder />
        <Menu.Item key="1" icon={<AiOutlineHome />}>
          Painel
        </Menu.Item>

        <Divider content={collapsedTitle('Pacotes', collapsed)} noBorder />

        <Menu.Item
          key="2"
          icon={<BsBoxSeam />}
          onClick={() => navigate('/painel/pacotes/')}
        >
          Ver Todos
        </Menu.Item>

        <Menu.Item
          key="3"
          icon={<BsClipboardPlus />}
          onClick={() => navigate('/painel/pacotes/novo')}
          disabled={!user?.canCreate}
        >
          Adicionar Novo
        </Menu.Item>

        <Menu.Item
          key="4"
          icon={<BsTrash />}
          onClick={() => navigate('/painel/pacotes/lixeira')}
          disabled={!user?.canDelete}
        >
          Lixeira
        </Menu.Item>

        <Menu.Item
          key="5"
          icon={<BsClipboardCheck />}
          onClick={() => navigate('/painel/pacotes/auditoria')}
          // disabled={!user?.canDelete}
        >
          Auditoria
        </Menu.Item>

        <Divider
          content={collapsedTitle('Configurações', collapsed)}
          noBorder
        />
        <Menu.Item
          key="7"
          icon={<IoReturnDownBackOutline />}
          onClick={() => Notification.info({ message: 'Em desenvolvimento' })}
        >
          Voltar
        </Menu.Item>

        <Menu.Item key="8" icon={<AiOutlineLogout />} onClick={handleLogout}>
          Sair
        </Menu.Item>
      </Menu>
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(LayoutSider);
