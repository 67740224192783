import React, { useEffect, useState } from 'react';
import { Form, InputNumber, Table, Skeleton } from 'antd';

import { connect, useDispatch, useSelector } from 'react-redux';

import { Card } from 'src/components';
import { table } from 'src/utils';
import {
  updatePackageItemQuantity,
  removePackageItem,
} from 'src/store/packages/actions';
import { UPDATE_PACKAGES } from 'src/store/packages/types';

import { IProps, EditableCellProps } from './table.type';
import { mainColumns, itemsColumns } from './table.utils';
import { IUser } from 'src/interfaces/user';
import { IReduxState } from 'src/store';

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[{ required: true }]}
        >
          <InputNumber min={1} />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TableComponent: React.FC<IProps> = (props) => {
  const { dataSource, loading } = props;

  const dispatch = useDispatch();
  const user: IUser = useSelector((state: IReduxState) => state.auth?.user);
  const [editingKey, setEditingKey] = useState<any>('');

  const [form] = Form.useForm();

  const [data, setData] = useState(dataSource);

  useEffect(() => {
    setData(dataSource);
    setEditingKey('');
  }, [dataSource]);

  const isEditing = (record: any) => {
    return record.id === editingKey;
  };

  const edit = (record: Partial<any> & { key: React.Key }) => {
    form.setFieldsValue({ quantity: '', ...record });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key: React.Key | any) => {
    try {
      const input = await form.getFieldsValue(['quantity']);

      const newData = [...data];

      let packageItemIndex = -1;
      let packageIndex = -1;
      let i = 0;

      for await (const itemData of newData) {
        const index = await itemData.packageItems.findIndex(
          (item: any) => key === item.id
        );

        if (index > -1) {
          packageItemIndex = index;
          packageIndex = i;
        }

        i++;
      }

      if (packageItemIndex > -1 && packageIndex > -1) {
        const res = await props.updatePackageItemQuantity(key, {
          quantity: Number(input.quantity),
        });

        if (res) {
          const item = newData[packageIndex];

          item.packageItems[packageItemIndex] = {
            ...res,
            orderSize: { ...item.packageItems[packageItemIndex].orderSize },
          };

          newData.splice(packageIndex, 1, {
            ...item,
          });

          setData('');
          dispatch({ type: UPDATE_PACKAGES, payload: newData });
        }

        setData(newData);
        setEditingKey('');
      } else {
        setData('');
        setEditingKey('');
      }
    } catch (e) {
      console.dir('Err', e);
    }
  };

  const handleRemove = async (id: string) => {
    try {
      const response = await props.removePackageItem(id);
      if (response) {
        props.handleSearch(props.filter, props.currentPage);
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  const mergedColumns = itemsColumns(
    {
      isEditing,
      editingKey,
      edit,
      save,
      cancel,
    },
    handleRemove,
    user
  ).map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const expandedRowRender = (record: any) => {
    return (
      <Form form={form} component={false} key={'f' + record.id}>
        <Table
          rowKey={(record: any) => 'c' + record.id}
          columns={mergedColumns}
          dataSource={record.packageItems}
          pagination={false}
          className="editable-row"
          bordered
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />
      </Form>
    );
  };

  return (
    <Card>
      {loading ? (
        <Skeleton active />
      ) : (
        <Table
          {...table}
          rowKey={(record: any) => 'r' + record.id}
          className="components-table-demo-nested "
          columns={mainColumns(user)}
          expandable={{ expandedRowRender }}
          dataSource={data}
          bordered
          pagination={false}
        />
      )}
    </Card>
  );
};

export default connect(undefined, {
  updatePackageItemQuantity,
  removePackageItem,
})(TableComponent);
