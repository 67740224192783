import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  padding-top: 24px;
  padding-bottom: 48px;
  width: 100%;

  @media (min-width: 320px) and (max-width: 768px) {
    max-width: 540px;
    padding: 0 20px;
    width: 100%;
    padding-top: 96px;
    padding-bottom: 48px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 890px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
    margin: 0 auto;
  }

  &:before {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
    content: ' ';
    display: table;
  }
`;

export const ContainerFluid = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 95%;
  width: 100vw;

  &:before {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
    content: ' ';
    display: table;
  }
`;
