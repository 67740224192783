import React from 'react';

import { Text, Divider } from './divider.style';
import { IProps } from './divider.type';

const DividerComponent: React.FC<IProps> = (props) => {
  const { content, noBorder } = props;

  return (
    <>
      {!noBorder && <Divider />}
      {!!content && <Text>{content}</Text>}
    </>
  );
};

export default DividerComponent;
