import React from 'react';
import Highcharts from 'highcharts';
import { Col } from 'antd';

import { Card } from 'src/components';

import { Chart } from './charts.style';
import { IProps } from './charts.type';

const ChartsComponent: React.FC<IProps> = (props) => {
  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Controle Anual de Pacotes',
    },
    subtitle: {
      text: '<div className="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed deiusmod tempor</div>',
    },
    xAxis: {
      categories: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ],
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Pacotes',
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: 'John',
        data: [
          49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1,
          95.6, 54.4,
        ],
      },
      {
        name: 'Mary',
        data: [
          48.9, 38.8, 39.3, 41.4, 47.0, 48.3, 59.0, 59.6, 52.4, 65.2, 59.3,
          51.2,
        ],
      },
      {
        name: 'Beth',
        data: [
          42.4, 33.2, 34.5, 39.7, 52.6, 75.5, 57.4, 60.4, 47.6, 39.1, 46.8,
          51.1,
        ],
      },
    ],
  };

  return (
    <Col span={24} lg={14}>
      <Card className="d-flex align-items-center justify-content-center">
        <Chart
          highcharts={Highcharts}
          options={options}
          width="100%"
          style={{ width: '100%' }}
        />
      </Card>
    </Col>
  );
};

export default ChartsComponent;
