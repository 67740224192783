import React from 'react';
import { Row, Descriptions, Table } from 'antd';

import { table } from 'src/utils';

import { IProps } from './step.type';
import { Left, Right } from './step.style';

const StepComponent: React.FC<IProps> = (props) => {
  const { measurements, currentOrder, stepReport, steps } = props;

  const handleColumns = () => {
    let sizes: [] = measurements.reduce(
      (previousValue: any, currentValue: any) => {
        let prev: any = previousValue;

        if (!!currentValue.quantity) prev.push(currentValue?.gridSize?.name);

        return prev;
      },
      []
    );

    const columns =
      sizes?.map((item: any, index: any) => ({
        key: index,
        title: item,
        dataIndex: ['sizes', `${item}`],
      })) || [];

    return columns;
  };

  const handleDataSource = () => {
    let orderSizeValues: any = [];
    let stepSizeValues: any = [];

    measurements.map(
      (item: any) => (orderSizeValues[item.gridSize.name] = item.quantity || 0)
    );

    stepReport?.map(
      (item: any) => (stepSizeValues[item.gridSizeName] = item.quantity_1 || 0)
    );

    return [
      { key: 'order', sizes: orderSizeValues },
      { key: 'step', sizes: stepSizeValues },
    ];
  };

  return (
    <Row gutter={16} className="mt-2">
      <Left span={8}>
        <Descriptions
          style={{ width: '100%' }}
          title={`OC: ${currentOrder?.cutOrder}`}
          bordered
          size={'small'}
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          className="mb-3 oc-description"
        >
          <Descriptions.Item label="Pedido:">
            {currentOrder?.orderNumber}
          </Descriptions.Item>
          <Descriptions.Item label={'Baixa:'}>
            {steps?.find(
              (item: any) =>
                item.id === Number(props.form.getFieldValue(['stepId']))
            )?.name || '-'}
          </Descriptions.Item>
        </Descriptions>
      </Left>
      <Right span={16}>
        <Table
          {...table}
          bordered
          pagination={false}
          columns={handleColumns()}
          dataSource={handleDataSource()}
          className="mb-3"
        />
      </Right>
    </Row>
  );
};

export default StepComponent;
