import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 8px;
  position: relative;
  z-index: 2;
`;

export const First = styled.div`
  background-image: linear-gradient(90deg, #4073b4, #5fca9c);
  flex: 1;
`;

export const Second = styled.div`
  background-color: #b40c23;
  flex: 1;
`;

export const Third = styled.div`
  background-color: #b40c23;
  flex: 1;
`;
