import { Route } from 'react-router-dom';

import PackageScreen from 'src/screens/package/package.component';
import PackageNewScreen from 'src/screens/package/new/new.component';
import PackageSplitScreen from 'src/screens/package/split/split.component';
import PackageErasedScreen from 'src/screens/package/erased/erased.component';
import AuditScreen from 'src/screens/package/audit/audit.component';

import { PrivateRoute } from 'src/screens/app/routes/user/user.utils';

const packageRouteGroup = (
  <Route path="pacotes">
    <Route index element={<PrivateRoute children={<PackageScreen />} />} />
    <Route
      path="novo"
      element={<PrivateRoute children={<PackageNewScreen />} />}
    />
    <Route
      path="item/:packageItemId/fragmentar"
      element={<PrivateRoute children={<PackageSplitScreen />} />}
    />
    <Route
      path="lixeira"
      element={<PrivateRoute children={<PackageErasedScreen />} />}
    />
    <Route
      path="auditoria"
      element={<PrivateRoute children={<AuditScreen />} />}
    />
  </Route>
);

export default packageRouteGroup;
