import React from 'react';
import { Row, Button, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { LogoutOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';

import { FiAlignLeft } from 'react-icons/fi';

import { images } from 'src/utils';
import { logout } from 'src/store/auth/actions';

import WrapperComponent from './components/wrapper/wrapper.component';
import { IProps } from './header.type';
import styles, { Logo, Title, Text } from './header.style';

const LayoutHeader: React.FC<IProps> = (props) => {
  const { handleDrawer, user } = props;

  const handleLogout = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | any
  ) => {
    props.logout();
  };

  return (
    <div className="site-layout-background p-0">
      {!!user ? (
        <>
          <WrapperComponent />
          <Row justify="space-between" style={styles.header} align="middle">
            <Button onClick={handleDrawer} style={styles.button}>
              <FiAlignLeft className="align-middle" color="white" />
            </Button>

            <div>
              {!isMobile && (
                <Title>
                  Bem-vindo <b> {(!!user && user.name) || ' Administrador'}</b>
                </Title>
              )}

              <Tooltip title="Sair">
                <Button
                  onClick={handleLogout}
                  style={{ ...styles.dropdown, ...styles.button }}
                  className="me-3"
                >
                  <LogoutOutlined style={styles.icon} className="me-1" />
                  <Text>Sair</Text>
                </Button>
              </Tooltip>
            </div>
          </Row>
        </>
      ) : (
        <Row justify="center" style={styles.headerLogin} align="middle">
          <Link to="/">
            <Logo src={images.logo.white} alt="logo" width="170px" />
          </Link>
        </Row>
      )}
    </div>
  );
};

export default connect(undefined, { logout })(LayoutHeader);
