import React from 'react';

import { IProps } from './container.type';
import { Container, ContainerFluid } from './container.style';

const ContainerComponent: React.FC<IProps> = (props) => {
  const { type, className, style, children } = props;

  switch (type) {
    case 'fluid':
      return (
        <ContainerFluid className={className} style={style}>
          {children}
        </ContainerFluid>
      );
    default:
      return (
        <Container className={className} style={style}>
          {children}
        </Container>
      );
  }
};

export default ContainerComponent;
