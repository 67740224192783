import React from 'react';
import { notification } from 'antd';
import {
  FrownOutlined,
  SmileOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import { INotification } from './notification.type';
import styles from './notification.style';

const Alert = {
  error: (options: INotification) => {
    const { title, message } = options;
    notification.destroy();
    notification.open({
      message: title || 'Erro',
      description: message,
      icon: <FrownOutlined style={styles.error} />,
    });
  },

  success: (options: INotification) => {
    const { title, message } = options;
    notification.destroy();
    notification.open({
      message: title || 'Ok',
      description: message,
      icon: <SmileOutlined style={styles.success} />,
    });
  },

  info: (options: INotification) => {
    const { title, message } = options;
    notification.destroy();
    notification.open({
      message: title || 'Informação',
      description: message,
      icon: <InfoCircleOutlined style={styles.info} />,
    });
  },

  warning: (options: INotification) => {
    const { title, message } = options;
    notification.destroy();
    notification.open({
      message: title || 'Informação',
      description: message,
      icon: <WarningOutlined style={styles.info} />,
    });
  },

  custom: (options: INotification) => {
    const { title, message, icon } = options;
    notification.destroy();
    notification.open({
      message: title || 'Informação',
      description: message,
      icon,
    });
  },
};

export default Alert;
