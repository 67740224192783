import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

import { IReduxState } from 'src/store';
import { scrollToTop } from 'src/utils';

import AuthScreen from 'src/screens/auth/auth.component';
import RedirectScreen from 'src/screens/auth/redirect/redirect.component';

interface IProps {
  name?: string;
  children: React.ReactElement<any, any> | null;
}

const PrivateRoute: React.FC<IProps> = ({ children, name }) => {
  scrollToTop();

  const [cookies] = useCookies();

  const token = !!useSelector((state: IReduxState) => state.auth?.token);
  const cookieToken = !!cookies?._jwtToken;

  const hasAccess = token && cookieToken;
  const hasPartialAccess = !token && cookieToken;

  return hasAccess ? (
    <Navigate to="/painel/pacotes" replace />
  ) : hasPartialAccess && name !== 'RedirectScreen' ? (
    <Navigate to="/redirect" replace />
  ) : (
    children
  );
};

const GuestRoutes = (
  <>
    <Route path="/">
      <Route index element={<PrivateRoute children={<AuthScreen />} />} />
      <Route
        path="redirect"
        element={
          <PrivateRoute name="RedirectScreen" children={<RedirectScreen />} />
        }
      />
    </Route>
  </>
);

export default GuestRoutes;
