import React from 'react';
import { Popconfirm } from 'antd';
import { IPackageItem } from 'src/interfaces/package';
import { TDataType } from './table.type';

export const updateRowsSum = (
  { quantity }: IPackageItem,
  rows: TDataType[]
) => {
  const sum = rows.reduce((previousValue: any, currentValue: any) => {
    return previousValue + currentValue.quantity;
  }, 0);

  return quantity - sum;
};

export const dataColumns = (handleDelete: (key: React.Key) => void) => [
  {
    title: 'Quantidade',
    dataIndex: 'quantity',
    editable: true,
  },
  {
    title: 'Ações',
    dataIndex: 'action',
    render: (text: any, record: { key: React.Key }) => (
      <Popconfirm
        title="Tem certeza?"
        onConfirm={() => handleDelete(record.key)}
      >
        <a href="/">Deletar</a>
      </Popconfirm>
    ),
  },
];

export const initialValues = (packageItem: IPackageItem) => [
  {
    key: 0,
    quantity: Math.trunc(packageItem.quantity / 2),
  },
  {
    key: 1,
    quantity: Math.trunc(packageItem.quantity / 2),
  },
];
