import { Dispatch } from 'react';

import StepsService from 'src/services/steps.service';
import { request } from 'src/utils';

import { FETCH_STEPS, StepsActionTypes } from './types';

export const fetchSteps =
  () => async (dispatch: Dispatch<StepsActionTypes>) => {
    try {
      const response = await StepsService.all();

      dispatch({
        type: FETCH_STEPS,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };
