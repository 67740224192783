import { api } from './';

const StepService = {
  all: async () => {
    try {
      const res = await api.get(`/steps`);
      return res.data;
    } catch (e) {
      throw e;
    }
  },
};

export default StepService;
