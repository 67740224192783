import React from 'react';
import { Row, Table, Descriptions } from 'antd';

import { table } from 'src/utils';

import { IProps } from './packs.type';
import { Right, Left } from './packs.style';

const PacksComponent: React.FC<IProps> = (props) => {
  const { measurements, packagesReport } = props;

  const sizes: [] = measurements.reduce(
    (previousValue: any, currentValue: any) => {
      let prev: any = previousValue;

      if (!!currentValue.quantity) prev.push(currentValue?.gridSize?.name);

      return prev;
    },
    []
  );

  const handleColumns = () => {
    const columns =
      sizes?.map((item: any, index: any) => ({
        key: index,
        title: item,
        dataIndex: ['sizes', `${item}`],
        render: (text: any) => text || '-',
      })) || [];

    return columns;
  };

  const handleDataSource = () => {
    let data: any = [];

    let rows = 0;

    for (const size of sizes) {
      const filter =
        packagesReport?.filter((pack: any) => pack.gridSizeName === size)
          .length || 0;

      if (filter > rows) {
        rows = filter;
      }
    }

    for (let rowIndex = 0; rowIndex < rows; rowIndex++) {
      for (const size of sizes) {
        const filter = packagesReport?.filter(
          (pack: any) => pack.gridSizeName === size
        );

        data[rowIndex] = {
          key: `packs-${rowIndex}`,
          sizes: {
            ...data[rowIndex]?.sizes,
            [size]: filter?.[rowIndex]?.quantity || 0,
          },
        };
      }
    }

    return data;
  };

  return (
    <Row gutter={16} className="mb-2">
      <Left span={8}>
        <Descriptions
          style={{ width: '100%' }}
          title="Pacotes"
          bordered
          size={'small'}
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          className="oc-description"
        >
          <Descriptions.Item label="Total de Pacotes:">
            {packagesReport?.length}
          </Descriptions.Item>
        </Descriptions>
      </Left>

      <Right span={16}>
        <Table
          className="no-header"
          {...table}
          columns={handleColumns()}
          dataSource={handleDataSource()}
          pagination={false}
          bordered
          scroll={{ x: 'auto', y: 300 }}
          summary={(pageData) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  {sizes.map((item: any, index) => {
                    const initialValue = 0;

                    const sum = pageData.reduce(
                      (accumulator: any, currentValue: any) =>
                        (accumulator + currentValue.sizes[item]) | 0,
                      initialValue
                    );

                    return (
                      <Table.Summary.Cell
                        index={index}
                        key={index}
                        className="text-center fw-bold text-success"
                      >
                        Total: {sum || 0}
                      </Table.Summary.Cell>
                    );
                  })}
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Right>
    </Row>
  );
};

export default PacksComponent;
