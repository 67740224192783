import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Layout, Drawer } from 'antd';
import { BrowserView, MobileView } from 'react-device-detect';

import { IReduxState } from 'src/store';

import Header from './components/header/header.component';
import Footer from './components/footer/footer.component';
import Menu from './components/sider/sider.component';

import { IProps } from './layout.type';
import styles from './layout.style';

const LayoutComponent: React.FC<IProps> = (props) => {
  const { children, user } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);
  const { Sider } = Layout;

  const handleDrawer = () => {
    setCollapsed(!collapsed);
    setVisible(!visible);
  };

  return (
    <Layout
      style={{ position: 'relative', minHeight: '100vh', paddingBottom: 45 }}
    >
      {!!user && (
        <>
          <MobileView>
            <Drawer
              placement="left"
              visible={visible}
              closable={false}
              onClose={() => setVisible(false)}
            >
              <Menu collapsed={collapsed} />
            </Drawer>
          </MobileView>
          <BrowserView>
            <Sider
              trigger={null}
              collapsible
              collapsed={collapsed}
              style={styles.sider}
            >
              <Menu collapsed={collapsed} />
            </Sider>
          </BrowserView>
        </>
      )}

      <Layout style={!user ? styles.contentLayoutLogin : styles.contentLayout}>
        <Header handleDrawer={handleDrawer} user={user} />
        {children}
        <Footer />
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(LayoutComponent);
