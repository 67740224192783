import { IStepReportFilter, IPackagesReportFilter } from 'src/interfaces/audit';
import { api } from './';

const AuditService = {
  fetchStepReport: async (filter: IStepReportFilter) => {
    try {
      const res = await api.get(
        `cut_orders_order_closes/details?cutOrderName=${filter.cutOrderName}&orderId=${filter.orderId}&stepId=${filter.stepId}`
      );
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  fetchPackagesReport: async (filter: IPackagesReportFilter) => {
    try {
      const res = await api.get(
        `package_items/group?cutOrderName=${filter.cutOrderName}&orderId=${filter.orderId}`
      );
      return res.data;
    } catch (e) {
      throw e;
    }
  },
};

export default AuditService;
