import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Row, Col, FormInstance, Table, Popconfirm, Button } from 'antd';
import { AiOutlinePlus } from 'react-icons/ai';

import { Card } from 'src/components';
import { Notification, table } from 'src/utils';
import { UPDATE_TABLE_ITEM } from 'src/store/packages/types';

import EditableCellComponent from './components/cell/cell.component';
import EditableRowComponent from './components/row/row.component';

import { IProps, TDataType } from './table.type';

export const EditableContext = React.createContext<FormInstance<any> | null>(
  null
);

const TableComponent: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();

  const [count, setCount]: any = useState(5);
  const [packages, setPackages]: any = useState([]);

  const sizesList = props.sizes;

  const handlePopulate = async () => {
    const sizes: any = [];

    try {
      for await (const size of sizesList) {
        sizes[size] = '0';
      }
    } finally {
      const initialValues = Array.from({ length: 1 }, (value, index) => ({
        key: index,
        sizes,
      }));

      setPackages(initialValues);
      dispatch({ type: UPDATE_TABLE_ITEM, payload: initialValues });
    }
  };

  const handleDelete = (key: any) => {
    const data = packages?.filter((item: any) => item.key !== key);

    if (!!data.length) {
      setPackages(data);
      dispatch({ type: UPDATE_TABLE_ITEM, payload: data });
    } else {
      Notification.warning({
        message: 'Você não pode cadastrar um pacotes sem items',
      });
    }
  };

  const handleAdd = async () => {
    const sizes: any = [];

    try {
      for await (const size of sizesList) {
        sizes[size] = '0';
      }
    } finally {
      const data: TDataType = {
        key: count,
        sizes,
      };

      setPackages([...packages, data]);
      dispatch({ type: UPDATE_TABLE_ITEM, payload: [...packages, data] });
      setCount(count + 1);
    }
  };

  const handleSave = (row: TDataType, values: any) => {
    const data = [...packages];

    const index = data.findIndex((item: any) => row.key === item.key);
    const item = data[index];

    const inputKey = Object.keys(values.sizes)[0];

    item.sizes[inputKey] = values.sizes[inputKey] || 0;

    data.splice(index, 1, {
      ...item,
      ...row,
    });

    setPackages(data);
    dispatch({ type: UPDATE_TABLE_ITEM, payload: data });
  };

  const components = {
    body: {
      row: EditableRowComponent,
      cell: EditableCellComponent,
    },
  };

  const columnsSizes =
    sizesList.map((item: any, index: any) => ({
      key: index,
      title: item,
      dataIndex: ['sizes', `${item}`],
      editable: true,
    })) || [];

  const dataColumns = [
    ...columnsSizes,
    {
      title: 'Ações',
      dataIndex: 'action',

      render: (text: any, record: { key: React.Key }) => (
        <Popconfirm
          title="Tem certeza?"
          onConfirm={() => handleDelete(record.key)}
        >
          <a href="/" tabIndex={-1}>
            Deletar
          </a>
        </Popconfirm>
      ),
    },
  ];

  const columns = dataColumns.map((col: any) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: TDataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  useEffect(() => {
    handlePopulate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <Row gutter={16}>
        <Col span={22}>
          <Table
            {...table}
            pagination={false}
            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            dataSource={packages}
            columns={columns}
          />
        </Col>
        <Col tabIndex={-1}>
          <Button
            tabIndex={-1}
            onClick={handleAdd}
            className="my-4"
            shape="circle"
          >
            <AiOutlinePlus tabIndex={-1} size={22} />
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default connect(undefined, {})(TableComponent);
