import styled from 'styled-components';

import { Col as AntdCol } from 'antd';

export const Left = styled(AntdCol)`
  align-items: flex-end;
  flex-direction: column;
  display: flex;
  flex: 1;
`;

export const Right = styled(AntdCol)``;
