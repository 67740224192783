import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 10px 0px;
  background: #d9ba77;
  text-align: center;
`;

export const Logo = styled.img`
  display: block;
  height: auto;
  max-width: 60px;
  margin: 20px auto 10px;
  width: 70%;

  @media (max-width: 992px) {
    max-width: 90px;
  }
`;

const Default = {
  layout: {
    minHeight: '100vh',
    paddingBottom: 45,
  },
  contentLayoutLogin: {
    backgroundImage: `linear-gradient(96deg, rgb(65, 117, 180), rgb(94 201 156 / 69%))`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
  },
  contentLayout: {
    padding: 0,
  },
  content: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    minHeight: 280,
    padding: 24,
  },
  button: {
    fontSize: 20,
    background: 'transparent',
    border: 0,
    color: '#1a1a1a',
    padding: 0,
    verticalAlign: 'middle',
  },
  sider: {
    borderTop: '8px solid #5fc99c',
  },
};

export default Default;
