import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { BrowserHistory, createBrowserHistory } from 'history';
import { ConfigProvider } from 'antd';
import antdLocale from 'antd/es/locale/pt_BR';

import { validateMessages } from 'src/utils';

import Layout from './components/layout/layout.component';
import RouteList from './routes/routes.component';

export const history: BrowserHistory = createBrowserHistory();

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ConfigProvider locale={antdLocale} form={{ validateMessages }}>
        <Layout>
          <RouteList />
        </Layout>
      </ConfigProvider>
    </BrowserRouter>
  );
};

export default App;
