import { IStep } from 'src/interfaces/steps';

export const FETCH_STEPS = 'FETCH_STEPS';

export interface IReducerStepsState {
  all?: IStep[];
}

export interface IFetchSteps {
  type: typeof FETCH_STEPS;
  payload: IStep[];
}

export type StepsActionTypes = IFetchSteps;
