import { Dispatch } from 'react';

import PackagesService from 'src/services/packages.service';

import {
  IPackage,
  ICreatePackageInput,
  IUpdateQuantityInput,
  IFilterPackageInput,
  IPaginatePackages,
  IPackageItem,
  ICreatePackageItemChildren,
} from 'src/interfaces/package';

import { request, Notification } from 'src/utils';
import { store } from 'src/store';

import {
  FETCH_PACKAGES,
  FETCH_PACKAGE,
  FETCH_PACKAGE_ITEM,
  FETCH_PACKAGES_FROM_ORDER_BY_CUT_ORDER,
  FETCH_ERASED_PACKAGE_ITEMS,
  UPDATE_TABLE_ITEM,
  UPDATE_FILTER_PACKAGES,
  PackagesActionTypes,
} from './types';

export const fetchPackages =
  (filter?: IFilterPackageInput, pageNumber?: number) =>
  async (dispatch: Dispatch<PackagesActionTypes>) => {
    try {
      const response: IPaginatePackages = await PackagesService.all(
        filter,
        pageNumber
      );

      dispatch({
        type: FETCH_PACKAGES,
        payload: response,
      });

      if (!!filter) {
        dispatch({
          type: UPDATE_FILTER_PACKAGES,
          payload: filter,
        });
      }

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const fetchPackage =
  (id: string) => async (dispatch: Dispatch<PackagesActionTypes>) => {
    try {
      const response: IPackage = await PackagesService.current(id);

      dispatch({
        type: FETCH_PACKAGE,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const fetchPackageItem =
  (id: string) => async (dispatch: Dispatch<PackagesActionTypes>) => {
    try {
      const response: IPackageItem = await PackagesService.currentPackageItem(
        id
      );

      dispatch({
        type: FETCH_PACKAGE_ITEM,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const fetchPackagesFromOrderByCutOrder =
  (orderId: string, cutOrderName: string) =>
  async (dispatch: Dispatch<PackagesActionTypes>) => {
    try {
      const response: IPackage[] =
        await PackagesService.fetchPackagesFromOrderByCutOrder(
          orderId,
          cutOrderName
        );

      dispatch({
        type: FETCH_PACKAGES_FROM_ORDER_BY_CUT_ORDER,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const fetchErasedPackageItems =
  (filter: IFilterPackageInput, pageNumber: number) =>
  async (dispatch: Dispatch<PackagesActionTypes>) => {
    try {
      const response: IPaginatePackages =
        await PackagesService.fetchErasedPackageItems(filter, pageNumber);

      dispatch({
        type: FETCH_ERASED_PACKAGE_ITEMS,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const createPackage = (input: ICreatePackageInput | any) => async () => {
  try {
    const dataSource = input.items;
    const tagsId = input.tags;

    const items: any = [];
    const tags = [];

    const rowsLength = dataSource?.length;

    let columnIndex = 0;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for await (const item of Object.entries(dataSource[0].sizes)) {
      for (let rowIndex = 0; rowIndex < rowsLength; rowIndex++) {
        let value = Object.entries(dataSource[rowIndex].sizes)[columnIndex];

        if (!!Number(value[1])) {
          const orderSizes = store.getState().orders.measurements;

          const orderSize = orderSizes?.find(
            (item: any) => item.gridSize?.name === value[0]
          );

          items.push({
            orderSizeId: orderSize?.id,
            quantity: Number(value[1]),
          });
        }
      }
      columnIndex++;
    }

    for await (const item of tagsId) {
      tags.push({ id: Number(item) });
    }

    if (!!items.length) {
      input.packageItems = items;
      input.tags = tags;

      delete input.items;

      const response: IPackage = await PackagesService.create(input);

      return response;
    }

    return Notification.error({
      message:
        'Você precisa informar a quantidade de pelo menos 1 item na tabela de medidas',
    });
  } catch (err) {
    request.errors(err);
  }
};

export const createPackageItemChildren =
  (packageItem: IPackageItem, values: any) => async () => {
    try {
      const data = values.filter((item: any) => item.quantity > 0);

      const input: ICreatePackageItemChildren = {
        quantity: packageItem.quantity,
        childrenPackageItems: data.map((item: any) => ({
          quantity: item.quantity,
        })),
      };

      if (input.childrenPackageItems.length > 1) {
        const response: any = await PackagesService.createPackageItemChildren(
          packageItem.id,
          input
        );

        return response;
      }

      return Notification.error({
        message: 'Você precisa fragmentar o pacote em pelo menos 2 pacotes.',
      });
    } catch (err) {
      request.errors(err);
    }
  };

export const updatePackageItemQuantity =
  (id: string, input: IUpdateQuantityInput) => async () => {
    try {
      const response: any = await PackagesService.updateQuantity(id, input);

      return response;
    } catch (err) {
      console.dir(err);
      request.errors(err);
    }
  };

export const removePackageItem = (id: string) => async () => {
  try {
    const response: any = await PackagesService.remove(id);
    return response;
  } catch (err) {
    request.errors(err);
  }
};

export const restorePackageItem = (id: string) => async () => {
  try {
    const response: any = await PackagesService.restore(id);
    return response;
  } catch (err) {
    request.errors(err);
  }
};

export const updateTableItem =
  (data: any) => async (dispatch: Dispatch<PackagesActionTypes>) => {
    dispatch({
      type: UPDATE_TABLE_ITEM,
      payload: data,
    });
  };
