import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Select, Input, Button } from 'antd';

import { Card } from 'src/components';
import { Notification } from 'src/utils';
import { IReduxState } from 'src/store';
import {
  fetchOrdersByCutOrder,
  fetchMeasurementsFromOrder,
  fetchStepReport,
  fetchPackagesReport,
  clearOrders,
} from 'src/store/audit/actions';
import { fetchSteps } from 'src/store/steps/actions';
import { IOrder } from 'src/interfaces/order';
import { IStep } from 'src/interfaces/steps';

import { rules } from './information.utils';
import { IProps } from './information.type';
import { IStepReportFilter } from 'src/interfaces/audit';

const { Search } = Input;

const FilterComponent: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [orders, setOrders] = useState<IOrder[] | undefined>([]);

  const { steps, form } = props;

  const handleOrders = async (value: string) => {
    setOrders([]);
    props.clearOrders();

    if (!!value) {
      setSearching(true);

      try {
        const data = await props.fetchOrdersByCutOrder(value);
        setOrders(data || undefined);
      } catch (e) {
        Notification.error({
          message: 'Não encontramos pedidos referente à essa ordem de corte.',
        });
        setOrders([]);
      } finally {
        setSearching(false);
      }
    }
  };

  const options = steps?.map((item: IStep) => ({
    key: item.id,
    value: item.id,
    label: item.name,
  }));

  const handleSteps = async () => {
    try {
      await props.fetchSteps();
    } catch (e) {
      Notification.error({ message: 'Ocorreu um erro ao buscar os estágios' });
    } finally {
      setLoading(false);
    }
  };

  const handleMeasurements = async (id: string) => {
    if (!!id) {
      try {
        await props.fetchMeasurementsFromOrder(id);
      } catch (e) {
        Notification.error({
          message:
            'Não encontramos tabela de medidas referente à esse pedido nessa ordem de corte.',
        });
      }
    }
  };

  const handleSubmit = async (values: IStepReportFilter) => {
    setLoading(true);

    try {
      const response = await props.fetchStepReport(values);

      if (!!response.length) {
        await props.fetchPackagesReport({
          cutOrderName: values.cutOrderName,
          orderId: values.orderId,
        });
      } else {
        Notification.error({
          message:
            'Ocorreu um erro ao buscar os dados da auditoria, tente novamente mais tarde.',
        });
      }
    } catch (e) {
      Notification.error({
        message:
          'Ocorreu um erro ao buscar os dados da auditoria, tente novamente mais tarde.',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="mb-0">
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => handleSubmit(values)}
      >
        <Row gutter={16}>
          <Col span={24} lg={8}>
            <Form.Item
              label="Ordem de Corte"
              name="cutOrderName"
              extra="Apenas números. Ex. 12345"
              rules={rules.cutOrder}
            >
              <Search
                allowClear
                placeholder="Ordem de Corte"
                onSearch={(value: string) => {
                  form.resetFields(['orderId', 'stepId']);
                  handleOrders(value);
                }}
                enterButton={
                  <h5 className="mb-0 text-white">Buscar Pedidos</h5>
                }
                loading={searching}
              />
            </Form.Item>
          </Col>

          {!!orders?.length && (
            <>
              <Col span={24} lg={8}>
                <Form.Item label="Pedidos" name="orderId" rules={rules.order}>
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Selecione..."
                    onChange={(e: any) => handleMeasurements(e)}
                    allowClear
                    onClear={() => form.resetFields(['stepId'])}
                  >
                    {orders.map((item: IOrder, index: any) => (
                      <Select.Option key={index} value={item.id}>
                        {item.orderNumber}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24} lg={8}>
                <Form.Item label="Estágio" name="stepId" rules={rules.step}>
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                    placeholder="Selecione..."
                    options={options}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {steps?.map((item: IStep, index: any) => (
                      <Select.Option
                        key={index}
                        value={item.id}
                        label={item.name}
                      >
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Button loading={loading} type="primary" htmlType="submit">
                Buscar
              </Button>
            </>
          )}
        </Row>
      </Form>
    </Card>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  steps: state.steps.all,
});

export default connect(mapStateToProps, {
  fetchSteps,
  fetchOrdersByCutOrder,
  fetchMeasurementsFromOrder,
  fetchStepReport,
  fetchPackagesReport,
  clearOrders,
})(FilterComponent);
