import React from 'react';
import { Row, Col, Descriptions, Badge, Tag } from 'antd';
import moment from 'moment';

import { Card } from 'src/components';

import { IProps } from './information.type';

const { Item } = Descriptions;

const InformationComponent: React.FC<IProps> = (props) => {
  const { packageItem } = props;

  return (
    <Card>
      <Row gutter={16}>
        <Col span={24}>
          <Descriptions
            bordered
            title={`Pacote #${packageItem.id}`}
            size="small"
          >
            <Item label="ID">{packageItem.id}</Item>
            <Item label="Lote">{packageItem.batch}</Item>
            <Item label="Status">
              {packageItem.active ? (
                <Badge status="success" text="Ativo" />
              ) : (
                <Badge status="default" text="Inativo" />
              )}
            </Item>
            <Item label="Qtde.">
              <Tag color="#53aa91">{packageItem.quantity}</Tag>
            </Item>
            <Item label="Tamanho">
              <Tag color="#4177b2">{packageItem.orderSize.gridSize.name}</Tag>
            </Item>
            <Item label="Criado em:">
              {moment(packageItem.createdAt).format('DD/MM/Y')}
            </Item>
            <Item label="Outras Informações">
              Ordem de Corte: {packageItem.package.cutOrder}
              <br />
              Pedido: {packageItem.package?.order?.orderNumber} (Nº)
              <br />
            </Item>
          </Descriptions>
        </Col>
      </Row>
    </Card>
  );
};

export default InformationComponent;
