import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Tooltip } from 'antd';
import { FiArrowLeftCircle } from 'react-icons/fi';

import { Button } from './go-back.style';
import { IProps } from './go-back.types';

const GoBackComponent: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const { color, className } = props;

  return (
    <Row justify="space-between" className={`${className || 'py-4'}`}>
      <Tooltip title="Voltar">
        <Button onClick={() => navigate(-1)} style={{ color }}>
          <FiArrowLeftCircle size="26" />
          Voltar
        </Button>
      </Tooltip>
    </Row>
  );
};

export default GoBackComponent;
