import React from 'react';
import { Form } from 'antd';

import { EditableContext } from '../../table.component';
import { IProps } from './row.type';

const EditableRowComponent: React.FC<IProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr tabIndex={-1} {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export default EditableRowComponent;
