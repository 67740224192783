import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;

export const Title = styled.h2`
  color: #444;
  font-weight: 400;
  text-align: center;
`;

export const Subtitle = styled.p`
  color: #888;
  font-weight: 400;
  text-align: center;
  font-size: 13px;
`;

export const Image = styled.img`
  display: flex;
  height: 35px;
  margin: auto auto 10px;
  width: auto;
`;
