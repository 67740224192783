import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  IReducerOrderState,
  FETCH_ORDER,
  FETCH_MEASUREMENTS_FROM_ORDER,
  FETCH_ORDERS_BY_CUT_ORDER,
  CLEAR_ORDERS,
  OrderActionTypes,
} from './types';

const initialState: IReducerOrderState = {
  all: undefined,
  current: undefined,
  measurements: undefined,
};

const persistConfig = {
  storage,
  key: 'orders',
  whitelist: [],
};

const reducers = (state = initialState, action: OrderActionTypes) => {
  switch (action.type) {
    case FETCH_ORDERS_BY_CUT_ORDER:
      return {
        ...state,
        all: action.payload,
      };
    case FETCH_ORDER:
      return {
        ...state,
        current: action.payload,
      };
    case FETCH_MEASUREMENTS_FROM_ORDER:
      return {
        ...state,
        measurements: action.payload,
      };
    case CLEAR_ORDERS:
      return {
        ...state,
        all: undefined,
        current: undefined,
        measurements: undefined,
      };
    default:
      return state;
  }
};

export default persistReducer(persistConfig, reducers);
