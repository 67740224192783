import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  IReducerAuditState,
  FETCH_ORDER,
  FETCH_MEASUREMENTS_FROM_ORDER,
  FETCH_ORDERS_BY_CUT_ORDER,
  CLEAR_ORDERS,
  FETCH_STEP_REPORT,
  FETCH_PACKAGES_REPORT,
  AuditActionTypes,
} from './types';

const initialState: IReducerAuditState = {
  orders: undefined,
  currentOrder: undefined,
  measurements: undefined,
  packagesReport: undefined,
  stepReport: undefined,
};

const persistConfig = {
  storage,
  key: 'audit',
  whitelist: [],
};

const reducers = (state = initialState, action: AuditActionTypes) => {
  switch (action.type) {
    case FETCH_ORDERS_BY_CUT_ORDER:
      return {
        ...state,
        orders: action.payload,
      };
    case FETCH_ORDER:
      return {
        ...state,
        currentOrder: action.payload,
      };
    case FETCH_MEASUREMENTS_FROM_ORDER:
      return {
        ...state,
        measurements: action.payload,
      };
    case FETCH_STEP_REPORT:
      return {
        ...state,
        stepReport: action.payload,
      };
    case FETCH_PACKAGES_REPORT:
      return {
        ...state,
        packagesReport: action.payload,
      };
    case CLEAR_ORDERS:
      return {
        ...state,
        orders: undefined,
        currentOrder: undefined,
        measurements: undefined,
        stepReport: undefined,
      };
    default:
      return state;
  }
};

export default persistReducer(persistConfig, reducers);
