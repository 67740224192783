import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Form,
  Select,
  Input,
  InputNumber,
  Divider,
  Checkbox,
} from 'antd';

import { Card } from 'src/components';
import { fetchOrdersByCutOrder, clearOrders } from 'src/store/orders/actions';
import { IOrder } from 'src/interfaces/order';
import { Notification } from 'src/utils';

import { rules } from './information.utils';
import { IProps } from './information.type';

const { Search } = Input;

const InformationComponent: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<IOrder[] | undefined>([]);

  const { form, cutNumber, tags } = props;

  const handleOrders = async (value: string) => {
    setOrders([]);

    if (!!value) {
      setLoading(true);

      try {
        const data = await props.fetchOrdersByCutOrder(value);
        setOrders(data || undefined);

      } catch (e) {
        Notification.error({
          message: 'Não encontramos pedidos referente à essa ordem de corte.',
        });

        props.clearOrders();
        setOrders([]);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Card>
      <Row gutter={16}>
        <Col span={24} lg={11}>
          <Form.Item
            label="Ordem de Corte"
            name="cutOrder"
            extra="Apenas números. Ex. 12345"
            rules={rules.cutOrder}
          >
            <Search
              allowClear
              placeholder="Ordem de Corte"
              onSearch={(value: string) => {
                form.resetFields(['orderId', 'cut']);
                handleOrders(value);
                props.handleMeasurements();
              }}
              enterButton={<h5 className="mb-0 text-white">Buscar Pedidos</h5>}
              loading={loading}
            />
          </Form.Item>
        </Col>

        {!!orders?.length && (
          <>
            <Col span={24} lg={10}>
              <Form.Item label="Pedidos" name="orderId" rules={rules.order}>
                <Select
                  style={{ width: '100%' }}
                  placeholder="Selecione..."
                  onChange={(e: any) => props.handleMeasurements(e)}
                >
                  {orders.map((item: IOrder, index: any) => (
                    <Select.Option key={index} value={item.id}>
                      {item.orderNumber}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {!!cutNumber && (
              <Col span={24} lg={3}>
                <Form.Item label="N° Corte" name="cut" initialValue={cutNumber}>
                  <InputNumber disabled style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            )}

            <Divider className="mt-0" />

            {!!tags?.length && (
              <Col span={24}>
                <Form.Item
                  label="Etiquetas"
                  name="tags"
                  extra="As etiquetas pré-selecionadas são referentes ao pacote anterior."
                  rules={rules.tags}
                >
                  <Checkbox.Group style={{ width: '100%' }}>
                    <Row gutter={24}>
                      {tags.map(({ id, name }: any, index: any) => (
                        <Col key={index}>
                          <Checkbox value={id}>{name}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            )}
          </>
        )}
      </Row>
    </Card>
  );
};

export default connect(undefined, { fetchOrdersByCutOrder, clearOrders })(
  InformationComponent
);
