import React from 'react';
import { Row, Col, Button, Input, Form } from 'antd';

import { Card } from 'src/components';

import { IProps } from './filter.type';

const FilterComponent: React.FC<IProps> = (props) => {
  const { filter, handleSearch, loading, currentPage } = props;

  return (
    <Card className="mb-0" title="Filtro">
      <Form
        layout="vertical"
        onFinish={(values) => handleSearch(values, currentPage)}
        initialValues={filter}
      >
        <Row gutter={16} align="middle">
          <Col span={24} md={12} lg={8}>
            <Form.Item label="Ordem de Corte" name="cutOrderName">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={24} md={12} lg={8}>
            <Form.Item label="ID do Pacote" name="packageItemId">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col>
            <Button
              className="mt-1"
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              Filtrar
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default FilterComponent;
